import React from "react";
import { string, bool } from "prop-types";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Box from "pages/_components/Box";
import classNames from "classnames";

function AlertInformation({ withIcon, title, background, textClassName, text, icon, sizeIcon }) {
    return (
        <Box
            background={background}
            border={`${background}-border-color`}
            className="p-5"
            display="flex"
            borderRadius="md">
            {withIcon && (
                <Box>
                    <Image
                        src={`images/icons/${`sb-${icon || background}.svg`}`}
                        wrapperWidth={sizeIcon}
                        wrapperHeight={sizeIcon}
                    />
                </Box>
            )}
            {title && withIcon ? (
                <Text color="heading" regular className={classNames("pl-3", textClassName)} labelKey={title} />
            ) : (
                title && <Text color="heading" className={textClassName} regular labelKey={title} />
            )}
            {text && withIcon ? (
                <Text color="heading" regular className={classNames("pl-3", textClassName)} children={text} />
            ) : (
                text && <Text color="heading" className={textClassName} regular children={text} />
            )}
        </Box>
    );
}
AlertInformation.defaultProps = {
    background: "info",
    withIcon: true,
    title: undefined,
    textClassName: "",
    sizeIcon: "4",
    icon: undefined,
};
AlertInformation.propTypes = {
    background: string,
    withIcon: bool,
    title: string,
    textClassName: string,
    sizeIcon: string,
    icon: string,
};
export default AlertInformation;
