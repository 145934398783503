import classNames from "classnames";
import { Form, Formik } from "formik";
import * as FormConfirmationMessages from "pages/forms/_components/_confirmations/Index";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as formSelectors } from "reducers/form";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import { getTransactionKind } from "util/transaction";
import * as Yup from "yup";
import SecondFactor from "pages/secondFactor/SecondFactor";
import { selectors as secondFactorSelectors } from "reducers/secondFactor";
import Loader from "pages/_components/Loader";

class FormConfirmation extends Component {
    static propTypes = {
        idForm: string,
        credentials: arrayOf(string).isRequired,
        dispatch: func.isRequired,
        submitAction: func.isRequired,
        submitActionParams: shape({
            idActivity: string.isRequired,
            values: shape({
                scheduler: oneOfType([string, shape({})]),
            }),
        }).isRequired,
        currentLang: string.isRequired,
        metadata: shape({}).isRequired,
        renderFields: func.isRequired,
        renderFieldsTermsAndConditions: func,
        showSchedulerMessage: bool,
        topDisclaimer: string,
        topDisclaimerAlignY: string,
        topDisclaimerTextColor: string,
        topDisclaimerTextSize: string,
        isDesktop: bool.isRequired,
        handleCancel: func,
        idActivity: string.isRequired,
        wally: bool,
        shouldEnableSubmit: bool,
        hideSecondFactor: bool,
        isFetchingSecondFactor: bool,
    };

    static defaultProps = {
        renderFieldsTermsAndConditions: null,
        idForm: "",
        showSchedulerMessage: true,
        topDisclaimer: null,
        topDisclaimerAlignY: null,
        topDisclaimerTextColor: null,
        topDisclaimerTextSize: null,
        handleCancel: undefined,
        wally: false,
        shouldEnableSubmit: false,
        hideSecondFactor: false,
        isFetchingSecondFactor: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: props.hideSecondFactor || false,
        };
    }

    setCanSubmit = (value = true) => {
        this.setState(() => ({ canSubmit: value }));
    };

    componentDidUpdate(prevProps) {
        const { shouldEnableSubmit } = this.props;

        if (shouldEnableSubmit !== prevProps.shouldEnableSubmit && shouldEnableSubmit !== undefined) {
            this.setCanSubmit(shouldEnableSubmit);
        }
    }

    validationSchema = () => {
        const { credentials } = this.props;
        return Yup.object().shape(
            credentials.reduce(
                (values, credential) => ({
                    ...values,
                    [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                }),
                {},
            ),
        );
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, submitAction, submitActionParams } = this.props;
        dispatch(submitAction({ ...submitActionParams, credentials, formikBag }));
    };

    renderDefaultConfirmation = () => {
        const { renderFields, submitActionParams, metadata, isDesktop, wally } = this.props;
        const titleKind = getTransactionKind(metadata?.idActivity);
        const ImagesWally = {
            wally: "images/phone-cash.svg",
            requestTransferWally: "images/request-wally.svg",
            pendingApproveWally: "images/clock-notebook-purple.svg",
        };
        const confirmationTitleKey = `forms.${titleKind}.confirmation.title`;
        return (
            <>
                {wally ? (
                    <Box alignX="center" alignY="center" borderRadius="default" fullWidth className="pb-5">
                        <Image
                            color="wally"
                            src={ImagesWally[titleKind] || "images/smartphone-wally.svg"}
                            height="10"
                            wrapperHeight="10"
                        />
                    </Box>
                ) : (
                    <Box
                        className={classNames({
                            "mb-2": isDesktop,
                            "mb-5": !isDesktop,
                        })}>
                        <Text
                            component="h2"
                            size={isDesktop ? "4" : "2"}
                            semibold
                            className="my-0 py-5"
                            color="heading"
                            labelKey={confirmationTitleKey}
                        />
                    </Box>
                )}
                <Box className={classNames("form-confirmation-content-fields", { wally })} position="relative">
                    {renderFields(submitActionParams.values)}
                </Box>
            </>
        );
    };

    render() {
        const {
            credentials,
            idForm,
            submitActionParams: {
                idActivity,
                values: { scheduler },
            },
            currentLang,
            metadata,
            renderFieldsTermsAndConditions,
            showSchedulerMessage,
            topDisclaimer,
            topDisclaimerAlignY,
            topDisclaimerTextColor,
            topDisclaimerTextSize,
            isDesktop,
            handleCancel,
            wally,
            hideSecondFactor,
            isFetchingSecondFactor,
        } = this.props;
        const { canSubmit } = this.state;

        const transactionKind = getTransactionKind(idActivity);

        const isCreditCardPaymentForm = transactionKind === "cardPayment";
        const isCreditCardRechargeForm = transactionKind === "recharge";

        const titleKind = transactionKind === "cardPayment" ? transactionKind : "scheduler";
        let FormConfirmationMessage;
        if (idForm !== null) {
            FormConfirmationMessage =
                FormConfirmationMessages[(idForm.charAt(0).toUpperCase() + idForm.substr(1)).replace(".", "_")];
        }

        let dynamicKey = i18n.generateFieldForm("button", idActivity, "confirm");
        if (!i18n.getI18Component("button", currentLang, idActivity, "confirm")) {
            dynamicKey = "global.confirm";
        }

        return (
            <>
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        ...credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), {}),
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}>
                    {(props) => (
                        <Form className="form-confirmation-wrapper">
                            <Box
                                background="white"
                                borderRadius="lg"
                                className={classNames("form-confirmation-box", {
                                    "box-shadow-small": isDesktop,
                                    wally,
                                    "mx-n-5": !wally,
                                })}>
                                <Row
                                    {...(wally ? { gapY: "4", fullHeight: true } : { heightAuto: true })}
                                    className={classNames({
                                        "mb-6": isDesktop,
                                    })}>
                                    <Col
                                        xs={12}
                                        md={8}
                                        mdOffset={2}
                                        className={classNames({ "full-height display-flex flex-column": wally })}>
                                        {wally && (
                                            <Box
                                                display="flex"
                                                column
                                                alignX="center"
                                                alignY="center"
                                                className="pt-7 pb-6">
                                                <Text
                                                    color="heading"
                                                    bold
                                                    size="2"
                                                    labelKey={`forms.${idActivity.replace(
                                                        ".send",
                                                        "",
                                                    )}.labelConfirmation`}
                                                />
                                            </Box>
                                        )}

                                        {!isCreditCardPaymentForm && !isCreditCardRechargeForm && topDisclaimer && (
                                            <Disclaimer
                                                styled="info"
                                                iconClassname="tooltip-img"
                                                borderRadius="top-xl"
                                                labelKey={topDisclaimer}
                                                notBorder
                                                alignY={topDisclaimerAlignY}
                                                textColor={topDisclaimerTextColor}
                                                textSize={topDisclaimerTextSize}
                                            />
                                        )}
                                        {wally ? (
                                            <Box display="flex" column>
                                                {FormConfirmationMessage ? (
                                                    <FormConfirmationMessage
                                                        metadata={metadata}
                                                        currentLang={currentLang}
                                                    />
                                                ) : (
                                                    this.renderDefaultConfirmation()
                                                )}
                                            </Box>
                                        ) : (
                                            <Box className="mb-4 form-confirmation-info-wrapper">
                                                {FormConfirmationMessage ? (
                                                    <FormConfirmationMessage
                                                        metadata={metadata}
                                                        currentLang={currentLang}
                                                    />
                                                ) : (
                                                    this.renderDefaultConfirmation()
                                                )}

                                                {showSchedulerMessage && scheduler && (
                                                    <div className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id={`forms.confirmation.${titleKind}`} />
                                                        </span>
                                                        <span className="data-text">
                                                            <Message value={scheduler} />
                                                        </span>
                                                    </div>
                                                )}
                                            </Box>
                                        )}
                                        {renderFieldsTermsAndConditions ? renderFieldsTermsAndConditions() : ""}
                                        {/* </Box> */}
                                        {/* <Box display="flex" alignX="center" column className="mt-auto">
                                            {credentials && wally && (
                                                <CredentialTokenComponent
                                                    credentials={credentials}
                                                    propsForm={props}
                                                    onChangeToken={(tokenCode) => {
                                                        if (props?.setFieldValue) {
                                                            props.setFieldValue("otp", tokenCode);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Box> */}
                                    </Col>
                                </Row>
                            </Box>
                            {!hideSecondFactor && (
                                <Box
                                    background="white"
                                    borderRadius="lg"
                                    // className={classNames("form-confirmation-box box-shadow-small", {
                                    //     wally,
                                    //     "mx-n-5": !wally,
                                    // })}>
                                    className={classNames("form-confirmation-box", {
                                        "box-shadow-small": isDesktop,
                                    })}>
                                    <Row className={classNames({ "mb-7 py-7": isDesktop })}>
                                        <Col
                                            xs={12}
                                            md={8}
                                            mdOffset={2}
                                            className={classNames({ "full-height display-flex flex-column": wally })}>
                                            {/* {credentials && !wally && (
                                                <CredentialTokenComponent
                                                    credentials={credentials}
                                                    propsForm={props}
                                                    onChangeToken={(tokenCode) => {
                                                        if (props?.setFieldValue) {
                                                            props.setFieldValue("otp", tokenCode);
                                                        }
                                                    }}
                                                />
                                            )} */}
                                            <SecondFactor
                                                onChangeToken={(tokenCode) => {
                                                    if (props?.setFieldValue) {
                                                        props.setFieldValue("secondFactor", tokenCode);
                                                        props.submitForm();
                                                    }
                                                }}
                                                onValidSubmit={(validData) => {
                                                    this.setCanSubmit(validData.submit);
                                                }}
                                                idActivity={idActivity}
                                                scopeToShow="form"
                                                isSubmitting={props.isSubmitting}
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            )}
                            {isFetchingSecondFactor ? (
                                <Loader />
                            ) : (
                                <Row {...(wally && { gapY: "3" })} className="mb-5">
                                    <Col
                                        sm={12}
                                        md={4}
                                        mdOffset={2}
                                        lg={3}
                                        lgOffset={3}
                                        className="col-12 mb-lg-4 d-flex align-items-end">
                                        <Button
                                            block
                                            type="button"
                                            label="global.cancel"
                                            bsStyle="outline"
                                            onClick={handleCancel}
                                            disabled={props.isSubmitting}
                                        />
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={4}
                                        lg={3}
                                        className={classNames("col-12 mb-lg-4 d-flex align-items-end", {
                                            "grid-reversed": !isDesktop,
                                        })}>
                                        <Button
                                            block
                                            type="submit"
                                            label={dynamicKey}
                                            bsStyle="primary"
                                            loading={props?.isSubmitting || false}
                                            disabled={props?.isSubmitting || !canSubmit || false}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    name: formSelectors.getName(state),
    // metadata: formSelectors.getMetadata(state),
    submitAction: formSelectors.getSubmitAction(state),
    submitActionParams: formSelectors.getSubmitActionParams(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
    isFetchingSecondFactor: secondFactorSelectors.isFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(FormConfirmation));
