import Box from "pages/_components/Box";
import Notification from "pages/_components/Notification";
import { shape, array } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import * as dateUtils from "util/date";
import Info from "pages/_components/Info";
import { isDesktop } from "react-device-detect";
import Image from "pages/_components/Image";
import * as config from "util/config";

import { isMobile } from "react-device-detect";
import Text from "pages/_components/Text";
import classNames from "classnames";
import { dictionaryStatusCreditCardCorporate } from "util/creditCards.js";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import AlertInformation from "pages/_components/AlertInformation";
import moment from "moment";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import CreditCardCorporateDetailPay from "pages/creditCards/_components/CreditCardCorporateDetailPay.jsx";

const INICIAL_FILTER_STATE = {
    dateFrom: dateUtils.getDateWithDaysSubtract(30),
    dateTo: new Date(),
    lastMovements: true,
    cardNumber: "",
};

class CorporateStatementDetail extends Component {
    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
    };

    state = {
        selectedFilter: "currentPeriod",
        resetDateFilters: false,
        showSideBarDownloadDetail: false,
        downloadingStatements: false,
        showSideBarSearchStatements: false,
        transitMovementSize: 0,
        showEnabledWallet: false,
        showWallet: false,
        isCardWasDigitized: undefined,
        showMovFilters: false,
        showAdditionalsFilters: false,
        filters: INICIAL_FILTER_STATE,
        hasFiltersApplied: false,
        additionalCards: array,
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
    };

    componentWillMount() {
        const { dispatch, creditCardCorporate, creditCardCorporateMovement } = this.props;
        const { filters } = this.state;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        const { type, idStatement } = creditCardCorporateMovement;
        dispatch(creditCardActions.corporateMovementDetailRequest(cardStyle, internalNumber, owner, type, idStatement));
    }

    renderDetails = (
        creditCardCorporateMovement,
        creditCardCorporate,
        creditCardCorporateMovementDetail,
        currency = "USD",
    ) => (
        <>
            <Box
                // borderRadius="lg"
                fullWidth
                display="flex"
                className="mb-7 mt-7"
                alignX={"space-between"}>
                <Box display="flex" alignY="center" fullWidth>
                    <Box display="flex" fullWidth alignX={"space-between"}>
                        <Box display="flex" alignY="center" className="mr-3">
                            <Image src="images/money-circle.svg" className="img-icon-service" />
                        </Box>
                        <Box display="flex" alignY="center" className={isDesktop ? "mr-6" : "mr-3"}>
                            <Text component="h3" size={!isDesktop ? "6" : null} bold>
                                Datos del movimiento
                            </Text>
                        </Box>
                    </Box>
                    {isMobile && (
                        <Box display="flex" alignY="center">
                            <Text
                                className={`p-3 text-no-wrap ${dictionaryStatusCreditCardCorporate(
                                    creditCardCorporateMovement.type,
                                    creditCardCorporateMovement.status,
                                )}`}
                                size="6">
                                {creditCardCorporateMovement.status.replace("_", " ")}
                            </Text>
                        </Box>
                    )}
                </Box>
            </Box>

            {creditCardCorporateMovement.type === "PAGOS" && (
                <>
                    {this.renderPago(
                        creditCardCorporateMovement,
                        creditCardCorporate,
                        creditCardCorporateMovementDetail,
                    )}
                </>
            )}
            {creditCardCorporateMovement.type === "NOTAS_CREDITO" && (
                <>
                    {this.renderNotaCredito(
                        creditCardCorporateMovement,
                        creditCardCorporate,
                        creditCardCorporateMovementDetail,
                    )}
                </>
            )}
            {creditCardCorporateMovement.type === "CONSUMOS" && creditCardCorporateMovement.status !== "CANCELADO" && (
                <>
                    {this.renderConsumoPendientePago(
                        creditCardCorporateMovement,
                        creditCardCorporate,
                        creditCardCorporateMovementDetail,
                    )}
                </>
            )}
            {creditCardCorporateMovement.type === "CONSUMOS" && creditCardCorporateMovement.status === "CANCELADO" && (
                <>
                    {this.renderConsumo(
                        creditCardCorporateMovement,
                        creditCardCorporate,
                        creditCardCorporateMovementDetail,
                    )}
                </>
            )}
            {creditCardCorporateMovement.type === "CARGOS" && (
                <>
                    {this.renderCargo(
                        creditCardCorporateMovement,
                        creditCardCorporate,
                        creditCardCorporateMovementDetail,
                    )}
                </>
            )}
        </>
    );

    renderGrid = (creditCardCorporateMovementDetail) => (
        <>
            {isDesktop ? (
                <PaginatedDataTable
                    nameList="consumptionDetails"
                    data={creditCardCorporateMovementDetail?.consumptionDetails || []}
                    rowsPerPage={config.getInteger("table.rowsPerPage", 10)}
                    component={CreditCardCorporateDetailPay}
                />
            ) : (
                <>
                    {creditCardCorporateMovementDetail?.consumptionDetails?.map((consumption) => (
                        <Box display="flex" alignX="between" alignY="center" className="border-radius-lg mb-4">
                            <Box display="flex">
                                <Box
                                    display="flex"
                                    alignY="center"
                                    column
                                    gapX="5"
                                    fullWidth
                                    className="item-account-list p-5 justify-content-start">
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.applicationDate"
                                        />
                                        <Info className="info-account-list" labelText={consumption.applicationDate} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.consumptionValue"
                                        />
                                        <Info className="info-account-list" labelText={consumption.consumptionValue} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.capital"
                                        />
                                        <Info className="info-account-list" labelText={consumption.capital} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.arrearsInterest"
                                        />
                                        <Info className="info-account-list" labelText={consumption.arrearsInterest} />
                                    </Box>
                                </Box>
                                <Box display="flex" alignY="start" column className="p-5">
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.consumptionId"
                                        />
                                        <Info className="info-account-list" text={consumption.consumptionId} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.appliedValue"
                                        />
                                        <Info className="info-account-list" labelText={consumption.appliedValue} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.normalInterest"
                                        />
                                        <Info className="info-account-list" labelText={consumption.normalInterest} />
                                    </Box>
                                    <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                        <Info
                                            className="info-account-list"
                                            labelKey="creditCard.corporate.movement.detail.creditNote.table.contributionSolca"
                                        />
                                        <Info className="info-account-list" labelText={consumption.contributionSolca} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </>
            )}
        </>
    );

    renderPago = (creditCardCorporateMovement, creditCardCorporate, creditCardCorporateMovementDetail) => (
        <>
            <Box
                className={classNames({
                    "pl-5 pr-5": !isDesktop,
                })}>
                <Box
                    display={isDesktop && "flex"}
                    className={`${isDesktop ? "mt-7" : "mt-5 display-flex flex-direction-column"}`}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.numberMask"
                        //labelText="Tarjeta"
                        text={creditCardCorporate.cardStyle + " " + creditCardCorporate.numberMask}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.nameOnCard"
                        //labelText="Nombre"
                        text={creditCardCorporate.nameOnCard}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.date"
                        //labelText="Fecha de pago"
                        date={creditCardCorporateMovement.date}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.chargeAmount"
                        //labelText="Monto USD"
                        amount={creditCardCorporateMovement.chargeAmount}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                </Box>
                <Box
                    display={isDesktop && "flex"}
                    className={classNames({
                        "mt-7": isDesktop,
                    })}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.applied"
                        //labelText="Valor aplicado USD"
                        amount={creditCardCorporateMovementDetail?.pay?.applied || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.confirm"
                        //labelText="Valor por confirmar USD"
                        amount={creditCardCorporateMovementDetail?.pay?.confirm || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.repayment"
                        //labelText="Valor devuelto USD"
                        amount={creditCardCorporateMovementDetail?.pay?.repayment || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.cash"
                        //labelText="Pago efectivo USD"
                        amount={creditCardCorporateMovementDetail?.pay?.cash || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                </Box>
                <Box
                    display={isDesktop && "flex"}
                    className={classNames("pb-7", {
                        "mt-7": isDesktop,
                    })}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.check"
                        //labelText="Pago cheque USD"
                        amount={creditCardCorporateMovementDetail?.pay?.check || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.pay.debitNote"
                        //labelText="Nota de débito USD"
                        amount={creditCardCorporateMovementDetail?.pay?.debitNote || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    {isDesktop && (
                        <>
                            <Info />
                            <Info />
                        </>
                    )}
                </Box>
            </Box>
            <Box
                // borderRadius="lg"
                fullWidth
                display="flex"
                className="pb-4 mb-7">
                <Box display="flex" alignY="center" className={isDesktop ? "mr-6 " : "mr-3"}>
                    <Image src="images/money-circle.svg" className="img-icon-service" />
                </Box>
                <Box display="flex" alignY="center" fullWidth>
                    <Box fullWidth>
                        <Box>
                            <Text component="h3" size={!isDesktop ? "6" : null} bold>
                                Detalle aplicacion del pago
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {creditCardCorporateMovement.status === "PENDIENTE" && (
                <Box fullWidth className="pb-4 mb-7">
                    <AlertInformation withIcon background="info" title="settings.corporate.Pay.alert" />
                </Box>
            )}
            {creditCardCorporateMovement.status !== "PENDIENTE" && (
                <>{this.renderGrid(creditCardCorporateMovementDetail)}</>
            )}
        </>
    );

    renderCargo = (creditCardCorporateMovement, creditCardCorporate, creditCardCorporateMovementDetail) => (
        <Box
            className={classNames({
                "pl-5 pr-5": !isDesktop,
            })}>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.charge.date"
                    //labelText="Fecha"
                    date={creditCardCorporateMovement.date}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.charge.sequentialBPM"
                    //labelText="Secuencial"
                    text={creditCardCorporateMovement.sequentialBPM}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.charge.startDate"
                    //labelText="Fecha inicial"
                    date={creditCardCorporateMovement.startDate}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.charge.expirationDate"
                    //labelText="Fecha de vencimiento"
                    date={creditCardCorporateMovement.expirationDate}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.charge.chargeAmount"
                    //labelText="Monto USD"
                    amount={creditCardCorporateMovement.chargeAmount}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                {isDesktop && (
                    <>
                        <Info />
                        <Info />
                        <Info />
                    </>
                )}
            </Box>
        </Box>
    );

    renderNotaCredito = (creditCardCorporateMovement, creditCardCorporate, creditCardCorporateMovementDetail) => (
        <>
            <Box
                className={classNames({
                    "pl-5 pr-5": !isDesktop,
                })}>
                <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.numberMask"
                        //labelText="Tarjeta"
                        text={creditCardCorporate.cardStyle + " " + creditCardCorporate.numberMask}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.nameOnCard"
                        //labelText="Nombre"
                        text={creditCardCorporate.nameOnCard}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.date"
                        //labelText="Fecha de pago"
                        date={creditCardCorporateMovement.date}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.chargeAmount"
                        //labelText="Monto USD"
                        amount={creditCardCorporateMovement.chargeAmount}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                </Box>
                <Box
                    display={isDesktop && "flex"}
                    className={classNames({
                        "mt-7": isDesktop,
                    })}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.appliedValue"
                        //labelText="Valor aplicado USD"
                        amount={creditCardCorporateMovementDetail?.pay?.appliedValue || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.confirm"
                        //labelText="Valor por confirmar USD"
                        amount={creditCardCorporateMovementDetail?.pay?.confirm || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.repayment"
                        //labelText="Valor devuelto USD"
                        amount={creditCardCorporateMovementDetail?.pay?.repayment || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.cash"
                        //labelText="Pago efectivo USD"
                        amount={creditCardCorporateMovementDetail?.pay?.cash || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                </Box>
                <Box
                    display={isDesktop && "flex"}
                    className={classNames("pb-7", {
                        "mt-7": isDesktop,
                    })}>
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.check"
                        //labelText="Pago cheque USD"
                        amount={creditCardCorporateMovementDetail?.pay?.check || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey="creditCard.corporate.movement.detail.creditNote.debitNote"
                        //labelText="Nota de débito USD"
                        amount={creditCardCorporateMovementDetail?.pay?.debitNote || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    {isDesktop && (
                        <>
                            <Info />
                            <Info />
                        </>
                    )}
                </Box>
            </Box>
            <Box
                // borderRadius="lg"
                fullWidth
                display="flex"
                className="pb-4 mb-7">
                <Box display="flex" alignY="center" className={isDesktop ? "mr-6 " : "mr-3"}>
                    <Image src="images/money-circle.svg" className="img-icon-service" />
                </Box>
                <Box display="flex" alignY="center" fullWidth>
                    <Box fullWidth>
                        <Box>
                            <Text component="h3" size={!isDesktop ? "6" : null} bold>
                                Detalle aplicacion del pago
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {creditCardCorporateMovement.status === "PENDIENTE" && (
                <Box fullWidth className="pb-4 mb-7">
                    <AlertInformation withIcon background="info" title="settings.corporate.Pay.alert" />
                </Box>
            )}
            {creditCardCorporateMovement.status !== "PENDIENTE" && (
                <>{this.renderGrid(creditCardCorporateMovementDetail)}</>
            )}
        </>
    );

    renderConsumo = (creditCardCorporateMovement, creditCardCorporate, creditCardCorporateMovementDetail) => (
        <Box
            className={classNames({
                "pl-5 pr-5": !isDesktop,
            })}>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.creditCard"
                    //labelText="Tarjeta"
                    text={creditCardCorporate.cardStyle + " " + creditCardCorporate.numberMask}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.name"
                    //labelText="Nombre"
                    text={creditCardCorporate.nameOnCard}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.payDate"
                    //labelText="Fecha de compra"
                    date={creditCardCorporateMovement.date}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.consumeCode"
                    //labelText="Codigo de consumo"
                    text={creditCardCorporateMovement.idStatement}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.value"
                    //labelText="Valor consumo USD"
                    amount={creditCardCorporateMovementDetail?.pay?.applied || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.operationDate"
                    //labelText="Fecha de operacion"
                    date={creditCardCorporateMovement.startDate}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.graceDate"
                    //labelText="Fecha vencimiento gracia"
                    text="-"
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.creditDate"
                    //labelText="Fecha vencimiento crédito"
                    text="-"
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.interestRate"
                    //labelText="Tasa de interés"
                    text={
                        creditCardCorporateMovementDetail?.consumption?.rate
                            ? creditCardCorporateMovementDetail?.consumption?.rate + "%"
                            : "-"
                    }
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.capitalBalance"
                    //labelText="Saldo capital USD"
                    amount={creditCardCorporateMovement?.consumption?.capitalBalance || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.interestBalance"
                    //labelText="Saldo interés USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.balanceInterest || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.balanceArrears"
                    //labelText="Saldo mora USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.balanceArrears || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.balanceSolca"
                    //labelText="Saldo solca USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.balanceSolca || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consume.balance"
                    //labelText="Saldo USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.balance || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                {isDesktop && (
                    <>
                        <Info />
                        <Info />
                    </>
                )}
            </Box>
        </Box>
    );

    renderConsumoPendientePago = (
        creditCardCorporateMovement,
        creditCardCorporate,
        creditCardCorporateMovementDetail,
    ) => (
        <Box
            className={classNames({
                "pl-5 pr-5": !isDesktop,
            })}>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.creditCard"
                    // labelText="Tarjeta"
                    text={creditCardCorporate.cardStyle + " " + creditCardCorporate.numberMask}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.name"
                    //labelText="Nombre"
                    text={creditCardCorporate.nameOnCard}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.payDate"
                    //labelText="Fecha de compra"
                    date={creditCardCorporateMovement.date}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.consumeCode"
                    //labelText="Codigo de consumo"
                    text={creditCardCorporateMovement.idStatement}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.consumeValue"
                    //labelText="Valor consumo USD"
                    amount={creditCardCorporateMovementDetail?.pay?.applied || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.graceDate"
                    //labelText="Fecha vencimiento gracia"
                    text="-"
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.creditDate"
                    //labelText="Fecha vencimiento crédito"
                    text="-"
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.interestRate"
                    //labelText="Tasa de interés"
                    text={
                        creditCardCorporateMovementDetail?.consumption?.rate
                            ? creditCardCorporateMovementDetail?.consumption?.rate + "%"
                            : "-"
                    }
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
            </Box>
            <Box
                display={isDesktop && "flex"}
                className={classNames({
                    "mt-7": isDesktop,
                })}>
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.interestTotal"
                    //labelText="Total interés USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.totalInterest || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                <Info
                    labelKey="creditCard.corporate.movement.detail.consumePending.balance"
                    //labelText="Saldo USD"
                    amount={creditCardCorporateMovementDetail?.consumption?.balance || "-"}
                    flex={!isDesktop}
                    className={!isDesktop && "flex-direction-column"}
                />
                {isDesktop && (
                    <>
                        <Info />
                        <Info />
                    </>
                )}
            </Box>
        </Box>
    );

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    onFinishDownload = () => {
        this.setState({
            downloadingStatements: false,
        });
    };

    handleClickStatementDownload = () => {
        const {
            dispatch,
            match,
            filters,
            creditCardCorporate,
            creditCardCorporateMovement,
            creditCardCorporateMovementDetail,
        } = this.props;
        this.setState({
            downloadingStatements: true,
        });
        dispatch(
            creditCardActions.downloadCorporateMovementDetail(
                creditCardCorporate.numberMask,
                creditCardCorporate.nameOnCard,
                creditCardCorporate.internalNumber,
                creditCardCorporate.owner,
                creditCardCorporateMovement.type,
                creditCardCorporateMovement.idStatement,
                this.onFinishDownload,
                moment(creditCardCorporateMovement.date)
                    .add(-1, "days")
                    .format("DD/MM/YYYY"),
                moment(creditCardCorporateMovement.date)
                    .add(1, "days")
                    .format("DD/MM/YYYY"),
            ),
        );
    };

    render() {
        const {
            creditCardCorporate,
            creditCardCorporateMovement,
            creditCardCorporateMovementDetail,
            isFetchingCorporateMovementDetail,
            isDesktop,
            dispatch,
        } = this.props;

        const secondaryBtn = () => {
            const secondaryBtnArr = [];
            const btnPayCard = {
                bsStyle: isDesktop ? "primary" : "only-icon",
                image: isDesktop ? "images/download.svg" : "images/util/download-mobile.svg",
                label: "creditcard.detail.tab.points.downloadPDF",
                permissionList: ["*"],
                onClick: () => this.handleClickStatementDownload("pdf"),
            };
            secondaryBtnArr.push(btnPayCard);

            return secondaryBtnArr;
        };

        const secondaryButtonList = secondaryBtn();

        return (
            <>
                <PageLoading loading={isFetchingCorporateMovementDetail} classicStyle={false}>
                    <Notification scopeToShow="CorporateMovementDetail" />
                    <ProductDetail>
                        <ProductDetail.Header
                            dispatch={dispatch}
                            handleOptionsClick={this.handleClick}
                            isDesktop={isDesktop}
                            onBack={this.handleBack}
                            product={creditCardCorporateMovement}
                            idProduct={creditCardCorporate?.internalNumber}
                            productId={creditCardCorporate?.idProduct}
                            secondaryBtn={secondaryButtonList}
                            textBack="global.return"
                            renderProductTitle={false}
                            productStatus={`creditCard.corporate.status.${creditCardCorporateMovement?.status}`}
                        />
                        <ProductDetail.Body
                            id="productDetail.body"
                            closeMoreFiltersWhenSiblingClick
                            isDesktop={isDesktop}
                            product={creditCardCorporate}
                            productKind="creditCards"
                            filters={[]}
                            {...(creditCardCorporate.isTherePendingTransaction && {
                                disclaimerStyled: "warning",
                                disclaimerLabelkey: "accounts.disclaimer.pendingTransaction",
                            })}
                            onSelect={() => this.handleClearFilterClick()}>
                            <Box fullWidth={isMobile}>
                                {this.renderDetails(
                                    creditCardCorporateMovement,
                                    creditCardCorporate,
                                    creditCardCorporateMovementDetail,
                                )}
                            </Box>
                        </ProductDetail.Body>
                    </ProductDetail>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { id, statement } = ownProps.match.params;
    return {
        creditCardCorporate: creditCardSelectors.getCreditCardCorporate(state, id),
        creditCardCorporateMovement: creditCardSelectors.getCorporateMovementAndConsume(state, statement),
        creditCardCorporateMovementDetail: creditCardSelectors.getCorporateMovementDetail(state),
        isFetchingCorporateMovementDetail: creditCardSelectors.isFetchingCorporateMovementDetail(state),
    };
};

CorporateStatementDetail.propTypes = {
    creditCardCorporate: shape({}),
    creditCard: shape({}),
};
CorporateStatementDetail.defaultProps = {
    creditCard: {},
};

export default connect(mapStateToProps)(CorporateStatementDetail);
