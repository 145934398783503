const creditCardBrandsValidations = {
    amex: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-7]/,
};

export const detectBrand = (number) =>
    Object.keys(creditCardBrandsValidations).find((key) => creditCardBrandsValidations[key].test(number));

export const format = (number) => number.match(/.{4}/g).join(" ");

export const CREDIT_CARD_RELATION_TYPE_MAIN = "Y";
export const CREDIT_CARD_RELATION_TYPE_ADDITIONAL = "N";

export const CREDIT_CARD_STATUS = {
    ACTIVA: "00",
};

export const CREDIT_CARD_TYPE_BAND = "band";
export const CREDIT_CARD_TYPE_BAND_TITLE = "creditcard.type.band.label";
export const CREDIT_CARD_TYPE_CARD = "card";
export const CREDIT_CARD_TYPE_CARD_TITLE = "creditcard.type.card.label";
export const CREDIT_CARD_TYPE_STICKER = "sticker";
export const CREDIT_CARD_TYPE_STICKER_TITLE = "creditcard.type.sticker.label";

export const MAP_CREDIT_CARD_TYPE = new Map([
    [CREDIT_CARD_TYPE_BAND, { icon: CREDIT_CARD_TYPE_BAND, title: CREDIT_CARD_TYPE_BAND_TITLE }],
    [CREDIT_CARD_TYPE_CARD, { icon: CREDIT_CARD_TYPE_CARD, title: CREDIT_CARD_TYPE_CARD_TITLE }],
    [CREDIT_CARD_TYPE_STICKER, { icon: CREDIT_CARD_TYPE_STICKER, title: CREDIT_CARD_TYPE_STICKER_TITLE }],
]);

export const generatePinAssigmentData = ({
    idProduct,
    numberMask,
    shortLabel,
    franchise,
    expirationDate,
    submitAction,
}) => {
    const cardBrand = franchise ? `${franchise.charAt(0).toUpperCase()}${franchise.slice(1).toLowerCase()}` : "";
    return {
        productType: "creditCards",
        idActivity: "creditCards.assignPin.send",
        productData: {
            idProduct,
            numberMask,
            shortLabel,
            franchise,
            parametersSubmit: { idCreditCard: idProduct, expirationDate },
        },
        title: `creditCards.drawer.pinAssignment.title`,
        isPinAssigment: true,
        paramsNotification: {
            success: {
                CARD_NUMBER: shortLabel,
                CARD_BRAND: cardBrand,
            },
            error: { CARD_NUMBER: shortLabel },
        },
        submitAction,
    };
};

export const getNonPrepaidCreditCardsWithAdditionals = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        const nonPrepaid = creditCards.filter((creditCard) => !creditCard.isPrepaid);

        // aplano listado de tarjetas a tarjetas y sus adicionales
        allCreditCards = nonPrepaid.reduce((acc, creditCard) => {
            acc.push(creditCard);
            if (creditCard.additionalCreditCardList != null) {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...creditCard.additionalCreditCardList];
            }
            return acc;
        }, []);
    }

    return allCreditCards;
};

export const getCreditCardsWithAdditionals = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        // aplano listado de tarjetas a tarjetas y sus adicionales
        allCreditCards = creditCards.reduce((acc, creditCard) => {
            acc.push(creditCard);
            if (creditCard.additionalCreditCardList != null) {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...creditCard.additionalCreditCardList];
            }
            return acc;
        }, []);
    }

    return allCreditCards;
};

export const getPrepaidCreditCards = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        allCreditCards = creditCards.filter((creditCard) => creditCard.isPrepaid);
    }

    return allCreditCards;
};

export const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
        return creditCardNumber;
    }

    const trimCreditCardNumber = creditCardNumber.replace(/\s/g, "");
    let newCreditCardNumber = "";

    if (trimCreditCardNumber.charAt(0) === "3") {
        if (trimCreditCardNumber.length > 10) {
            newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
                4,
                10,
            )} ${trimCreditCardNumber.substring(10)}`;
        } else if (trimCreditCardNumber.length > 4) {
            newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(4)}`;
        } else {
            newCreditCardNumber = trimCreditCardNumber;
        }
    } else if (trimCreditCardNumber.length > 12) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
            4,
            8,
        )} ${trimCreditCardNumber.substring(8, 12)} ${trimCreditCardNumber.substring(12)}`;
    } else if (trimCreditCardNumber.length > 8) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
            4,
            8,
        )} ${trimCreditCardNumber.substring(8)}`;
    } else if (trimCreditCardNumber.length > 4) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(4)}`;
    } else {
        newCreditCardNumber = trimCreditCardNumber;
    }

    return newCreditCardNumber;
};

export const dictionaryCardStyle = (cardStyle) => {
    if (!cardStyle) {
        return 'white'; 
    }
    // if (cardStyle.includes('EMPRESARIAL')) {
    //     return 'bankarddiamond';
    // } else if (cardStyle.includes('CLASICA')) {
    //     return 'classic';
    // } else if (cardStyle.includes('ORO')) {
    //     return 'gold';
    // } else if (cardStyle.includes('PLATINUM')) {
    //     return 'platinum';
    // } else if (cardStyle.includes('INFINITE')) {
    //     return 'infinite';
    // } else if (cardStyle.includes('UNIONPAY')) {
    //     return 'unionPay';
    // } else if (cardStyle.includes('BLACK')) {
    //     return 'black';
    // } else if (cardStyle.includes('BANKARD') && cardStyle.includes('VISA')) {
    //     return 'bankardvisa';
    // } else if (cardStyle.includes('BANKARD') && cardStyle.includes('UNIONPAY')) {
    //     return 'bankardunionPay';
    // } else if (cardStyle.includes('BANKARD') && cardStyle.includes('DIAMONT')) {
    //     return 'bankarddiamond';
    // } else if (cardStyle.includes('BANKARD') && cardStyle.includes('BUSINESS')) {
    //     return 'bankardbusiness';
    // } else if (cardStyle.includes('BANKARD')) {
    //     return 'bankard';
    // } else
    if (cardStyle.includes('VISA')) {
        return 'black';
    } else if (cardStyle.includes('MASTER')) {
        return 'black';
    } else if (cardStyle.includes('UNIONPAY')) {
        return 'black';
    } else if (cardStyle.includes('UPI EMPRESARIAL')) {
        return 'black';
    } else if (cardStyle.includes('BANKARD')) {
        return 'white';
    } else {
        return 'white';
    }
};

export const dictionaryMiniatureCard = (cardStyle) => {
    if (!cardStyle) {
        return 'bankard'; 
    }
    if (cardStyle.includes('VISA')) {
        return 'visa';
    } else if (cardStyle.includes('MASTER')) {
        return 'mastercard';
    } else if (cardStyle.includes('UNIONPAY')) {
        return 'unionPay';
    } else if (cardStyle.includes('UPI EMPRESARIAL')) {
        return 'unionPay';
    } else if (cardStyle.includes('BANKARD')) {
        return 'bankard';
    } else {
        return 'bankard';
    }
};

export const getAllowedAdditionalCards=(primaryCard, creditCards) => {
    if (!primaryCard || !creditCards || creditCards.length === 0) {
        return [];
    }
    const primaryCardCodeMatrixPermissions = primaryCard.codeMatrixPermissions;
    const additionalCards = creditCards.filter(
        (card) => card.codeMatrixPermissions === primaryCardCodeMatrixPermissions,
    );

    return additionalCards;
};

export const dictionaryStatusCreditCardCorporate = (type, status) => {
    if (!type || !status) {
        return "default";
    }
    switch (type) {
        case "CONSUMOS":
            switch (status) {
                case "CANCELADO":
                    return "product-status-corp-text-grey";
                case "CASTIGADO":
                    return "product-status-corp-text-red";
                case "EN_GRACIA":
                    return "product-status-corp-text-blue";
                case "GRACIA":
                    return "product-status-corp-text-blue";
                case "RESOLUCION":
                    return "product-status-corp-text-blue";
                case "VENCIDO":
                    return "product-status-corp-text-red";
                case "VIGENTE":
                    return "product-status-corp-text-green";
                default:
                    return "product-status-corp-text-grey";
            }
            break;
        case "PAGOS":
            switch (status) {
                case "APLICADO_SIN_AFECTAR":
                    return "product-status-corp-text-grey";
                case "CONDONACION":
                    return "product-status-corp-text-grey";
                case "CANCELADO_PARCIALMENTE":
                    return "product-status-corp-text-yellow";
                case "DIFERIDO":
                    return "product-status-corp-text-grey";
                case "PARCIALMENTE_APLICADO":
                    return "product-status-corp-text-yellow";
                case "PENDIENTE":
                    return "product-status-corp-text-blue";
                case "RECHAZADO":
                    return "product-status-corp-text-red";
                case "REVERSADO":
                    return "product-status-corp-text-red";
                case "TOTALMENTE_APLICADO":
                    return "product-status-corp-text-green";
                default:
                    return "product-status-corp-text-grey";
            }
            break;
        case "NOTAS_CREDITO":
            switch (status) {
                case "PENDIENTE":
                    return "product-status-corp-text-blue";
                case "PARCIALMENTE_APLICADO":
                    return "product-status-corp-text-yellow";
                case "TOTALMENTE_APLICADO":
                    return "product-status-corp-text-green";
                case "APLICADO_SIN_AFECTAR":
                    return "product-status-corp-text-grey";
                default:
                    return "product-status-corp-text-grey";
            }
            break;
        case "CARGOS":
            switch (status) {
                case "VENCIDO":
                    return "product-status-corp-text-red";
                case "VIGENTE":
                    return "product-status-corp-text-green";
                case "CANCELADO":
                    return "product-status-corp-text-grey";
                case "CASTIGADO":
                    return "product-status-corp-text-red";
                default:
                    return "product-status-corp-text-grey";
            }
            break;
        default:
            return "default";
    }
};

export const dictionaryCreditCardCorporateCode = (code) => {
    switch (code) {
        case "1":
            return "CONSUMOS";
        case "2":
            return "CARGOS";
        case "3":
            return "NOTAS_DEBITO";
        case "4":
            return "NOTAS_CREDITO";
        case "5":
            return "PAGOS";
        default:
            return "TODOS";
    }
};

export const dictionaryCreditCardCorporateMovementTittle = (type, status) => {
    switch (type) {
        case "NOTAS_CREDITO":
            return "Detalle de nota de crédito";
        case "PAGOS":
            return "Detalle de pago";
        case "CONSUMOS":
            switch (status) {
                case "CANCELADO":
                    return "Detalle de consumo";
                default:
                    return "Detalle de consumo pendiente de pago";
            }
        case "CARGOS":
            return "Detalles de cargos";
        default:
            return "Detalles de movimiento";
    }
};

export const getCurrentEnvironmentId = (currentEnvironment, environments) => {
    let currectEnv = "";
    if (environments?.length > 0) {
        currectEnv = environments.find((env)=>env.idEnvironment === currentEnvironment.id)
    }
    return currectEnv.productGroupId;
}
