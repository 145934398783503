import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Row from "pages/_components/Row";
import Credential from "pages/_components/fields/credentials/Credential";
import SecondFactor from "pages/secondFactor/SecondFactor";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";

class AdministrationFormConfirmation extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        dispatch: func.isRequired,
        onSubmit: func.isRequired,
        otherFieldFunction: func,
        otherInitialValues: shape({}),
        renderOutOfFormik: bool,
        cancelUrl: string,
        onClickCancel: func,
        fetching: bool.isRequired,
        isDesktop: bool,
        idActivity: string,
        scopeToShow: string,
    };

    static defaultProps = {
        otherFieldFunction: undefined,
        otherInitialValues: {},
        renderOutOfFormik: false,
        cancelUrl: "/administration/users",
        onClickCancel: null,
        isDesktop: true,
        idActivity: null,
        scopeToShow: undefined,
    };

    state = {
        canSubmit: false,
    };

    renderCredentials = (credentialGroupsFiltered) =>
        credentialGroupsFiltered.map((credential) => (
            <div key={credential}>
                <Field idForm="form.credential" name={credential} component={Credential} type={credential} autoFocus />
            </div>
        ));

    renderCredentialsToken = (idActivity, propsForm, scopeToShow) => (
        <>
            <SecondFactor
                onChangeToken={(tokenCode) => {
                    if (propsForm.setFieldValue) {
                        propsForm.setFieldValue("secondFactor", tokenCode);
                        setTimeout(() => {
                            propsForm.submitForm();
                        }, 0);
                    }
                }}
                onValidSubmit={(validData) => {
                    this.setState({ canSubmit: validData.submit });
                }}
                idActivity={idActivity}
                scopeToShow={scopeToShow}
                isSubmitting={propsForm.isSubmitting}
            />
        </>
    );

    render() {
        const {
            credentialGroups,
            dispatch,
            onSubmit,
            otherFieldFunction,
            otherInitialValues,
            renderOutOfFormik,
            cancelUrl,
            onClickCancel,
            fetching,
            isDesktop,
            idActivity,
            scopeToShow,
            isFetchingSecondFactor,
        } = this.props;
        const { canSubmit } = this.state;

        const credentialGroupsFiltered = compose(
            (array) => array.filter((item) => item !== "accessToken"),
            removeDuplicateItems,
            flattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(credentialGroups);
        const initialValues = { secondFactor: "" };

        if (!Object.keys(initialValues).length) {
            return null;
        }
        const validationSchema = Yup.object().shape({
            secondFactor: Yup.string().required(i18n.get("form.credential.otp.required")),
        });

        if (renderOutOfFormik) {
            return this.renderCredentials(credentialGroupsFiltered);
        }

        return (
            <Formik
                initialValues={{ ...initialValues, ...otherInitialValues }}
                vvalidationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onSubmit}>
                {(propsForm) => (
                    <Form className="position-relative">
                        <Box
                            className={classNames("mt-5", {
                                "p-7 border-radius-lg box-shadow-small background-white": isDesktop,
                            })}>
                            {otherFieldFunction && otherFieldFunction()}
                            <section className="container--layout items-center">
                                <Container
                                    className="container--layout items-center flex-grow-1"
                                    gridClassName="form-content">
                                    <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                        {this.renderCredentialsToken(idActivity, propsForm, scopeToShow)}
                                    </Col>
                                </Container>
                            </section>
                        </Box>
                        <Row gapY="7" className="mt-7">
                            <Col lg={6} lgOffset={3}>
                                <Row
                                    className={classNames("justify-content-center", {
                                        "d-flex flex-column-reverse": !isDesktop,
                                    })}>
                                    <Col xs={6}>
                                        <Button
                                            bsStyle="outline"
                                            label="global.cancel"
                                            onClick={() => {
                                                if (onClickCancel) {
                                                    onClickCancel();
                                                } else {
                                                    dispatch(push(cancelUrl));
                                                }
                                            }}
                                            btnUppercase={false}
                                            block
                                            disabled={propsForm?.isSubmitting || isFetchingSecondFactor}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            type="submit"
                                            label="global.confirm"
                                            bsStyle="primary"
                                            loading={propsForm?.isSubmitting || false || fetching}
                                            btnUppercase={false}
                                            block
                                            disabled={!canSubmit}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect()(AdministrationFormConfirmation);
