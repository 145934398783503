/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import moment from "moment";
import { TYPE_FILTER } from "constants.js";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text/Text";
import Select from "pages/forms/_components/_fields/Select";
import { bool, func } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import DateField from "pages/_components/fields/DateField";
import FieldError from "pages/_components/fields/FieldError";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import TableOperationalDocuments from "./_components/TableOperationalDocuments";

const DocumentsOperational = ({ dispatch, isDesktop, isFetching }) => {
    const rowsPerPage = configUtil.getInteger("documents.rowsPerPage") || 10;
    const maxRangeDays = configUtil.getInteger("documents.range.maxdays");
    // const minDateRange = configUtil.getInteger("documents.range.months.min");
    const minDateRange = 42;
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedTypeProduct, setSelectedTypeProduct] = useState("");
    const [selectedTransaction, setSelectedTransaction] = useState("");
    const [selectedDocumentType, setSelectedDocumentType] = useState("");
    const [selectedTypeFilter, setSelectedTypeFilter] = useState(TYPE_FILTER.DATE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(false);
    const [errorRange, setErrorRange] = useState(false);
    const [errorAccount, setErrorAccount] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);
    const [inputNumberOfcase, setInputNumberOfcase] = useState("");
    const [filters, setFilters] = useState({});
    const [dateFieldKey, setDateFieldKey] = useState(0);

    const [operationType, setOperationType] = useState("");
    const [newTypeProduct, setNewTypeProduct] = useState("");
    const [newTransaction, setNewTransaction] = useState("");
    

    const handleResetDates = () => {
        setStartDate(null);
        setEndDate(null);
        setDateFieldKey(prevKey => prevKey + 1);
    };

    const productOptionsSelect = () => {
        const salida = [];

        const productValues = configUtil.get("documents.operational.list.typeOperation");
        const productValuesArray = productValues.split("|");

        for (let index = 0; index < productValuesArray.length; index++) {
            const elementValue = productValuesArray[index];

            salida.push({
                value: elementValue,
                label: i18n.get(`documents.operational.typeOperation.${elementValue}.text`),
            });
        }
        return salida;
    };

    const typeProductOptionsSelect = () => {
        const salida = [{}];

        const typeProductValues = configUtil.get("documents.operational.relationship.typeOperation_typeProduct");
        const typeProductValuesArray = typeProductValues.split("|");

        const typeComercio = typeProductValuesArray[0];
        const comercioArray = typeComercio.split(":");
        const nameComercio = comercioArray[0];

        const typePrestamo = typeProductValuesArray[1];
        const prestamoArray = typePrestamo.split(":");
        const namePrestamo = prestamoArray[0];

        if (selectedProduct === nameComercio) {
            // es CEX
            const comercioValues = comercioArray[1];
            const typeCEXValues = configUtil.get("documents.operational.relationship.typeProduct_typeTransaction");
            const typeCEXValuesArray = typeCEXValues.split("|");

            for (let index = 0; index < typeCEXValuesArray.length; index++) {
                const elementValue = typeCEXValuesArray[index];
                const nameValue = elementValue.split(":");

                if (nameValue[0] === "CEX") {
                    const cexSplit = nameValue[1].split(",");
                    const typeTransaction = cexSplit[1];

                    salida.push({
                        value: typeTransaction,
                        label: i18n.get(`documents.operational.typeProduct.${nameValue[0]}.${typeTransaction}.text`),
                    });
                }
            }
            salida.shift();
        } else if (selectedProduct === namePrestamo) {
            const prestamoValues = prestamoArray[1];
            const splitValues = prestamoValues.split(",");

            for (let index = 0; index < splitValues.length; index++) {
                const element = splitValues[index];

                salida.push({
                    value: element,
                    label: i18n.get(`documents.operational.typeProduct.${element}.text`),
                });
            }
            salida.shift();
        }

        return salida;
    };

    const transactionOptionsSelect = () => {
        const salida = [{}];

        const relationValues = configUtil.get("documents.operational.relationship.typeProduct_typeTransaction");
        const splitRelationValues = relationValues.split("|");

        for (let index = 0; index < splitRelationValues.length; index++) {
            const elementValue = splitRelationValues[index];
            const nameValue = elementValue.split(":");

            if (nameValue[0] === "CEX" && selectedProduct === "COMERCIO_EXTERIOR" && selectedTypeProduct !== "") {
                setSelectedDocumentType("L");
                salida.push({
                    value: "L",
                    label: i18n.get(`documents.operational.typeTransaction.liquidation.text`),
                });
                salida.shift();
                return salida;
            }
            if (nameValue[0] === "CCA" && selectedProduct === "PRESTAMO" && selectedTypeProduct === "CCA") {
                setSelectedDocumentType("");
                const ccaSplit = nameValue[1].split(",");
                const typeCCATransaction = ccaSplit[1];

                salida.push({
                    value: typeCCATransaction + index,
                    label: ccaSplit[0],
                });
            } else if (nameValue[0] === "LEA" && selectedProduct === "PRESTAMO" && selectedTypeProduct === "LEA") {
                setSelectedDocumentType("");
                const leaSplit = nameValue[1].split(",");
                const typeLEATransaction = leaSplit[1];

                salida.push({
                    value: typeLEATransaction + index,
                    label: leaSplit[0],
                });
            } else if (nameValue[0] === "FAC" && selectedProduct === "PRESTAMO" && selectedTypeProduct === "FAC") {
                setSelectedDocumentType("");
                const facSplit = nameValue[1].split(",");
                const typeFACTransaction = facSplit[1];

                salida.push({
                    value: typeFACTransaction + index,
                    label: facSplit[0],
                });
            }
        }

        if (salida.length > 1) {
            salida.shift();
        }

        return salida;
    };

    useEffect(() => {
        dispatch(documentsActions.clean());
        setSelectedTypeFilter(TYPE_FILTER.DATE);
        setSelectedTypeProduct("");
        setSelectedProduct("");

    }, []);

    const isValidForm = () => {
        const isDateFilterValid =
            selectedTypeFilter === TYPE_FILTER.DATE &&
            !!startDate &&
            !!endDate &&
            endDate.isSameOrAfter(startDate) &&
            !errorRange;

        const areSelectionsValid =
            !!selectedProduct && !!selectedTypeProduct && !!selectedTransaction;

        return isDateFilterValid && areSelectionsValid;
    };

    const handleClickFilter = () => {
        let inputFilers = {
            selectedDocumentType,
            startDate,
            endDate,
        };
        
        if (newTypeProduct === "CEX") {
            inputFilers = {
                ...inputFilers,
                selectedTransaction : newTransaction,
                selectedProduct: operationType,
                selectedTypeProduct: newTypeProduct,
            };
        } else {
            inputFilers = {
                ...inputFilers,
                selectedTransaction,
                selectedProduct,
                selectedTypeProduct: newTypeProduct,
            };
        }

        setFilters(inputFilers);
        setAfterSearch(true);
        dispatch(documentsActions.operationalDocumentsRequest(inputFilers));
    };

    const handleStartDate = (date) => {
        if (endDate) {
            if (date.isAfter(endDate)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (endDate.diff(date, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        if (startDate) {
            if (startDate.isAfter(date)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (date.diff(startDate, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setEndDate(date);
    };

    const renderFilterDate = () => (
        <>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateFrom.label"
                    className="data-label"
                    size="5"
                    semibold
                />
                <DateField
                    key={`startDate-${dateFieldKey}`}
                    idForm="checks.filters"
                    name="startDate"
                    field={{ name: "startDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleStartDate}
                    minDate={moment()
                        .add(-minDateRange, "months")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={startDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                />
            </Col>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateTo.label"
                    className="data-label"
                    size="5"
                    semibold
                />
                <DateField
                    key={`endDate-${dateFieldKey}`}
                    idForm="checks.filters"
                    name="endDate"
                    field={{ name: "endDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleEndDate}
                    minDate={startDate || moment().add(-minDateRange, "months")}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={endDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                />
            </Col>

            {!isDesktop && <>{renderErrors()}</>}
        </>
    );

    const renderFilterDesktop = () => (
        <Col xs={12} md={9}>
            <Box>
                <Row alignY="flex-start" gapX="7" gapY="5">
                    {/* producto */}
                    <Col xs={12} md={6} className="align-items-baseline">
                        <Box className="form-group form-group--select">
                            <Box alignY="center" className="data-label-special-mb mt-0 display-flex">
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="documents.operational.list.filter.product.label"
                                    className="mt-0"
                                    size="5"
                                    semibold
                                />
                            </Box>
                            <Box className="input-group">
                                <Select
                                    id="accountBy"
                                    name="accountBy"
                                    placeholder={i18n.get("documents.swift.international.account.placeholder")}
                                    onChange={(option) => {
                                     if (selectedProduct !== option.value) {
                                        setSelectedProduct(option.value);
                                        setOperationType(option.value);
                                        setNewTypeProduct("");
                                        if(option.value == "COMERCIO_EXTERIOR") {
                                            setNewTypeProduct("CEX");
                                        }
                                        setSelectedTypeProduct("");
                                        setSelectedTransaction("");
                                        setErrorAccount(false);
                                        handleResetDates();
                                     }
                                    }}
                                    options={productOptionsSelect()}
                                    value={selectedProduct}
                                    applyCustomStyles={!isDesktop}
                                />
                            </Box>
                        </Box>
                        {renderErrorAccount()}
                    </Col>
                    {/* tipo producto */}
                    <Col xs={12} md={6} className="align-items-baseline">
                        <Box className="form-group form-group--select">
                            <Box alignY="center" className="data-label-special-mb mt-0 display-flex">
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="documents.operational.list.filter.typeProduct.label"
                                    className="mt-0"
                                    size="5"
                                    semibold
                                />
                            </Box>
                            <Box className="input-group">
                                <Select
                                    id="accountBy"
                                    name="accountBy"
                                    placeholder={i18n.get("documents.swift.international.account.placeholder")}
                                    onChange={(option) => {
                                        if( option.label && option.value ) {
                                            if (selectedTypeProduct !== option.value) {

                                                if(newTypeProduct != "CEX") {
                                                    setNewTypeProduct(option.value);
                                                }
                                                setSelectedTransaction("");

                                                if(newTypeProduct == "CEX") {
                                                    setNewTransaction(option.value)
                                                }

                                                setSelectedTypeProduct(option.value);
                                                setErrorAccount(false);
                                                handleResetDates();
                                            }
                                        }
                                    }}
                                    options={typeProductOptionsSelect()}
                                    value={selectedTypeProduct}
                                    applyCustomStyles={!isDesktop}
                                />
                            </Box>
                        </Box>
                        {renderErrorAccount()}
                    </Col>
                </Row>
            </Box>

            <Box className="mt-5">
                <Row alignY="flex-end" gapX="7" gapY="5">
                    {/* transaccion */}
                    <Col xs={12} md={3} className="align-items-baseline">
                        <Box className="form-group form-group--select">
                            <Box alignY="center" className="data-label-special-mb mt-0 display-flex">
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="documents.operational.list.filter.transaction.label"
                                    className="mt-0"
                                    size="5"
                                    semibold
                                />
                            </Box>
                            <Box className="input-group">
                                <Select
                                    id="accountBy"
                                    name="accountBy"
                                    placeholder={i18n.get("documents.swift.international.account.placeholder")}
                                    onChange={(option) => {
                                        if( option.label && option.value ) {
                                            if (selectedTransaction !== option.value) {
                                                setSelectedTransaction(option.value);
                                                setErrorAccount(false);
                                                handleResetDates();
                                            }
                                        }
                                    }}
                                    options={transactionOptionsSelect()}
                                    value={selectedTransaction}
                                    applyCustomStyles={!isDesktop}
                                />
                            </Box>
                        </Box>
                        {renderErrorAccount()}
                    </Col>
                    {selectedTypeFilter === TYPE_FILTER.DATE && renderFilterDate()}

                    <Col xs={12} md={3} className="pt-5">
                        <Button
                            label="global.search"
                            bsStyle="primary"
                            className={classNames("mt-auto full-width", { "px-9": isDesktop, "px-8": !isDesktop })}
                            disabled={!isValidForm()}
                            onClick={handleClickFilter}
                        />
                    </Col>
                </Row>
                <Row gapX="7" gapY="3">
                    <Col xsOffset={3} xs={6} mdOffset={3} md={6} lgOffset={3} lg={6}>
                        {isDesktop && <>{renderErrors()}</>}
                    </Col>
                </Row>
            </Box>
        </Col>
    );

    const renderErrorAccount = () => (
        <>
            {errorAccount && (
                <Box className="pt-2 pb-5 form-errors-color">
                    <FieldError error={i18n.get("documents.swift.account.validation")} />
                </Box>
            )}
        </>
    );

    const renderErrors = () => (
        <>
            {errorRange && (
                <Box className="pt-2 pb-5 size-5 form-errors-color pos-relative">
                    <FieldError error={i18n.get("documents.operational.filter.period.msg.error.range")} />
                </Box>
            )}
        </>
    );

    return (
        <>
            <Notification scopeToShow="documents" />
            <Head
                title="documents.operational.header.tittle"
                backLinkTo="/documents"
                textBack="documents.tax.header.returnToDocuments"
            />

            <MainContainer
                className="px-0 border-radius-lg box-shadow-small texture-header background-white mt-5"
                showLoader={isFetching}>
                <Box
                    borderRadius="lg"
                    background="white"
                    className={classNames("mt-5", {
                        "p-7": isDesktop,
                        "p-5": !isDesktop,
                    })}>
                    <Box background="white" className={classNames("mt-5 pt-2 pb-7")}>
                        <Box
                            component="article"
                            background="white"
                            borderRadius="default"
                            className={classNames("mb-5", {
                                "pt-5 ": isDesktop,
                                "px-5 pb-5": !isDesktop,
                            })}
                            toBorderInMobile>
                            <TableOperationalDocuments
                                afterSearch={afterSearch}
                                rowsPerPage={rowsPerPage}
                                renderFilterDesktop={renderFilterDesktop}
                            />
                        </Box>
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

DocumentsOperational.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(DocumentsOperational));
