/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import moment from "moment";
import { PRODUCT_TYPE, REMITTANCE_CHECK_STATUS } from "constants.js";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Table from "rc-table";
import Text from "pages/_components/Text/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import * as numberUtils from "util/number";
import PaginatorButtonsBar from "pages/_components/PaginatorButtonsBar";
import DateField from "pages/_components/fields/DateField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import Image from "pages/_components/Image";
import NoResults from "pages/_components/NoResultsMessage";
import { Collapse } from "react-bootstrap";
import Tooltip from "pages/_components/Tooltip";
import I18n from "pages/_components/I18n";
import RemittanceCheckItem from "./_components/RemittanceCheckItem";
import { actions as notificationActions } from "reducers/notification";
 

const RemittanceChecks = (props) => {
    const {
        dispatch,
        isDesktop,
        isFetching,
        checks,
        accounts,
        totalChecks,
        totalCount,
        fetchingForm,
        totalAmountChecks,
        filtersRemittanceChecks,
        formProducts,
    } = props;

    const checksPerPage = configUtil.getInteger("remittance.checksPerPage") || 10;
    // const checksPerPage = 1;
    const maxRangeDays = configUtil.getInteger("checks.range.maxdays");
    const minDateRange = configUtil.getInteger("remittance.checks.range.months.min") || 12;
    const [open, setOpen] = useState(true);
    const blueStatus = ["C", "G", "H"];
    const redStatus = ["A", "D", "E", "F", "O", "R", "S", "T", "V", "W", "Y"];
    const grayStatus = ["N", "X"];

    const statusOptionsSelect = [
        {
            value: REMITTANCE_CHECK_STATUS.ALL,
            label: i18n.get("remittance.checks.status.all", "Todos"),
        },
        {
            value: REMITTANCE_CHECK_STATUS.PENDING,
            label: i18n.get("remittance.checks.status.pending", "Pendiente"),
        },
        {
            value: REMITTANCE_CHECK_STATUS.CONFIRMED,
            label: i18n.get("remittance.checks.status.confirmed", "Confirmado"),
        },
        {
            value: REMITTANCE_CHECK_STATUS.RETURNED,
            label: i18n.get("remittance.checks.status.returned", "Devuelto"),
        },
    ];

    const getStatusLabelByValue = (value) => {
        const statusOption = statusOptionsSelect.find((option) => option.value === value);
        return statusOption ? statusOption.label : null;
    };

    const accountsOptions =
        formProducts && formProducts.length > 0
            ? formProducts
                  .map((account) => ({
                      value: account.idProduct,
                      label: `${account.numberMask} - ${account.ownerName} - ${account.productTypeBackend} - ${account.currency}`,
                  }))
            : [];

    const [downloading, setDownloading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [selectedStatusFilter, setSelectedStatusFilter] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorRange, setErrorRange] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [offset, setOffset] = useState(0);

    const getCurrentChecks = () => checks.slice(offset, offset + checksPerPage);

    const currentChecks = getCurrentChecks();

    const handleClickDownload = (format) => {
        const filters = {
            idAccount: selectedAccount,
            status: selectedStatusFilter,
            startDate,
        };
        if (isValidForm()) {
            setDownloading(true);
            dispatch(checksActions.downloadRemittanceChecks(filters, format, onFinishDownload));
        }
    };

    const documents = [
        {
            label: "global.pdfFile",
            onClick: () => handleClickDownload("pdf"),
        },
        {
            label: "global.xlsFile",
            onClick: () => handleClickDownload("xls"),
        },
        {
            label: "global.txtFile",
            onClick: () => handleClickDownload("txt"),
        },
    ];

    useEffect(() => {
        dispatch(formActions.preCustomForm(["check.remittance"]));
    }, []);

    useEffect(() => {

        if(filtersRemittanceChecks){
            setSelectedAccount(filtersRemittanceChecks.idAccount);
            setSelectedStatusFilter(filtersRemittanceChecks.status);
            setStartDate(filtersRemittanceChecks.startDate);
        }                  
        
    }, []);

    const handleClickFilter = () => {
        if (isValidForm()) {
            setAfterSearch(true);
            dispatch(checksActions.listRemittanceChecksRequest(selectedAccount, selectedStatusFilter, startDate));
        }
    };

    const handleStartDate = (date) => {
        setStartDate(date);
    };

    const handleBack = () => {
        dispatch(push(`/desktop`));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleClickFilter();
        }
    };

    const onRowClick = (record) => {
        dispatch(push(`/checks/list-remittance/${record.key}`));
    };

    const isValidForm = () => !!startDate && !errorRange && !!selectedAccount && !!selectedStatusFilter;

    const renderHeaderList = () => {
        const result = [
            {
                key: "checkNumber",
                dataIndex: "checkNumber",
                title: i18n.get("remittance.checks.table.item.number", "Cheque"),
                width: 75,
            },
            {
                key: "accountNumber",
                dataIndex: "accountNumber",
                title: i18n.get("remittance.checks.table.item.account", "Cuenta girada"),
                width: 75,
            },
            {
                key: "entryDate",
                dataIndex: "entryDate",
                title: i18n.get("remittance.checks.table.item.entryDate", "Fecha Ingreso"),
                width: 75,
            },
            {
                key: "confirmationDate",
                dataIndex: "confirmationDate",
                title: i18n.get("remittance.checks.table.item.confirmationDate", "Fecha Confirmación"),
                width: 75,
            },
            {
                key: "status",
                dataIndex: "status",
                title: i18n.get("remittance.checks.table.item.status", "Estado"),
                width: 75,
            },
            {
                key: "value",
                dataIndex: "value",
                title: i18n.get("remittance.checks.table.item.amount", "Monto USD"),
                width: 75,
                align: "right",
            },
        ];
        return result;
    };

    const renderFilterDesktop = () => {
        if (!fetchingForm && (!accountsOptions || accountsOptions.length === 0)) {
            dispatch(replace("/desktop"))
            setTimeout(() => {dispatch(notificationActions.showNotification(i18n.get("returnCode.API040E"), "warning", ["desktop"]))}, 100);
        }
        return (
            <Col xs={12} md={9}>
                <Box>
                    <Row alignY="flex-end" gapX={7} gapY={3}>
                        <Col xs={12} md={4}>
                            <Box className="form-group form-group--select">
                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                    <Text
                                        component="label"
                                        htmlFor="react-select-accountBy-input"
                                        labelKey="checkbooks.list.filter.accountBy.label"
                                        size="5"
                                        bold
                                    />
                                </Box>
                                <Box className="input-group">
                                    <Select
                                        id="accountBy"
                                        name="accountBy"
                                        label="remittance.checks.filter.startDate.select.label"
                                        onChange={(option) => {
                                            if (option) {
                                                setSelectedAccount(option.value);
                                            }
                                        }}
                                        options={accountsOptions}
                                        value={selectedAccount}
                                        placeholderKey="remittance.checks.filter.startDate.select.label"
                                        noOptionsKey="desktop.widgets.accounts.empty"
                                    />
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={3}>
                            <Box display="flex" className="align-items-center">
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="checkbooks.filter.dateFrom.label"
                                    size="5"
                                    bold
                                />
                                <Tooltip
                                    image="images/icons/circle-question.svg"
                                    text="remittance.checks.tooltip.startDate.info"
                                    position="top-left"
                                    className="align-self-center text-black pb-3"
                                    showProgressBar={false}
                                    onHover={isDesktop}
                                />
                            </Box>
                            <DateField
                                idForm="checks.filters"
                                name="startDate"
                                field={{ name: "startDate" }}
                                hideLabel
                                labelNoMarginTop
                                handleChange={handleStartDate}
                                minDate={moment().add(-minDateRange, "months")}
                                maxDate={endDate || moment()}
                                popperPlacement="bottom"
                                valueSelectedDate={startDate}
                                popperModifiers={{
                                    flip: {
                                        behavior: ["bottom"],
                                    },
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Box className="form-group form-group--select">
                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                    <Text
                                        component="label"
                                        htmlFor="react-select-filterType-input"
                                        labelKey="remittance.checks.filter.status.label"
                                        size="5"
                                        bold
                                    />
                                </Box>
                                <Box className="input-group">
                                    <Select
                                        id="filterType"
                                        name="filterType"
                                        label="transactions.list.filter.accountBy.label"
                                        onChange={(option) => {
                                            setSelectedStatusFilter(option.value);
                                        }}
                                        options={statusOptionsSelect}
                                        value={selectedStatusFilter}
                                        placeholderKey="remittance.checks.filter.status.select.placeholder"
                                    />
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                label="global.search"
                                bsStyle="primary"
                                className={classNames("mt-auto full-width", { "px-9": isDesktop, "px-8": !isDesktop })}
                                disabled={!isValidForm()}
                                onClick={handleClickFilter}
                            />
                        </Col>
                    </Row>
                </Box>
            </Col>
        )
    } ;

    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center">
                <Text labelKey="checks.none.text" />
            </Box>
        </Box>
    );
    const onFinishDownload = () => {
        setDownloading(false);
    };

    const paginate = (nextIndex) => {
        setOffset(nextIndex);
    };

    const goToFirstPage = () => {
        paginate(0);
        setPageNumber(1);
    };

    const goToPreviousPage = () => {
        paginate((pageNumber - 1) * checksPerPage - checksPerPage);
        setPageNumber(pageNumber - 1);
    };

    const goToNextPage = () => {
        paginate((pageNumber + 1) * checksPerPage - checksPerPage);
        setPageNumber(pageNumber + 1);
    };

    const goToLastPage = () => {
        if (totalCount % checksPerPage === 0) {
            paginate(totalCount - checksPerPage);
        } else {
            paginate(Math.floor(totalCount / checksPerPage) * checksPerPage);
        }
        setPageNumber(Math.ceil(totalCount / checksPerPage));
    };

    const getChecksData = () =>
        currentChecks.map((check) => ({
            key: check.checkNumber,
            checkNumber: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.checkNumber}</span>
                </div>
            ),
            accountNumber: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.accountNumber}</span>
                </div>
            ),
            entryDate: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.entryDate}</span>
                </div>
            ),
            confirmationDate: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.confirmationDate}</span>
                </div>
            ),
            status: (
                <div
                    className={classNames(
                        "fit-content-width",
                        { "check-status check-status-text": check.status === "C" },
                        { "check-status check-status-err-text": check.status === "T" },
                        { "check-status check-status-war-text": check.status === "E" },
                    )}>
                    <span className="data-desc">{getStatusLabelByValue(check.status)}</span>
                </div>
            ),
            value: (
                <div className="data-wrapper data-wrapper-flex justify-content-flex-end" align="right">
                    <FormattedAmount quantity={check.value} noCurrency />
                </div>
            ),
        }));

    const getResultCounter = () => (
        <Text
            labelKey="global.pagination.results"
            color="text"
            size="5"
            rows={
                totalCount < 1
                    ? totalCount
                    : pageNumber === 1
                    ? currentChecks?.length
                    : (pageNumber - 1) * checksPerPage + currentChecks?.length
            }
            totalRows={totalCount}
        />
    );

    const getTooltipInfo = () => (
        <Tooltip
            image="images/icons/circle-info.svg"
            text="remittance.checks.tooltip.downloads.info"
            position="top-right"
            className="align-self-center"
            showProgressBar={false}
            onHover={isDesktop}
        />
    );

    const renderBalances = (currency, totalChecks, remittanceBalance) => (
        <>
            {!isDesktop ? (
                <>
                    <Box borderRadius="lg" background="background-primary" className="p-7">
                        <Box fullWidth className="justify-content-center">
                            <Box display="flex" column className="justify-content-center mb-3 text-center check-amount">
                                <I18n id="remittance.checks.detail.remittanceBalance" defaultValue="Saldo remesas" />
                                <FormattedAmount
                                    noAmountMargin
                                    className="justify-content-center size-1 text-bold mt-3"
                                    currency={currency}
                                    quantity={remittanceBalance}
                                />
                            </Box>
                        </Box>
                        <Box fullWidth>
                            <Box display="flex" className="justify-content-center mt-3 mb-3">
                                <Box className="mr-3">
                                    <I18n id="remittance.checks.detail.totalChecks" defaultValue="Total de cheques" />
                                </Box>
                                <FormattedAmount
                                    noAmountMargin
                                    noCurrency
                                    noDecimalsWhenRound
                                    className="data-amount-account"
                                    currency={currency}
                                    quantity={totalChecks}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box
                    borderRadius="lg"
                    background="background-primary"
                    display="flex"
                    className="p-7 children-border-right">
                    <Box fullWidth>
                        <Box className="fit-content-width text-left">
                            <I18n id="remittance.checks.detail.totalChecks" defaultValue="Total de cheques" />
                            <FormattedAmount
                                noAmountMargin
                                noCurrency
                                noDecimalsWhenRound
                                className="data-amount-account"
                                currency={currency}
                                quantity={totalChecks}
                            />
                        </Box>
                    </Box>
                    <Box fullWidth className="text-webkit-right">
                        <Box className="fit-content-width text-right">
                            <I18n id="remittance.checks.detail.remittanceBalance" defaultValue="Saldo remesas" />
                            <FormattedAmount
                                noAmountMargin
                                className="data-amount-account"
                                currency={currency}
                                quantity={remittanceBalance}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );

    return (
        <>
            <Notification scopeToShow="checks" />
            <Head
                title="remittance.checks.header.search.title"
                // {...(checks && checks.length > 0 && { exportList: documents })}
                // exportList={documents}
                {...(!isDesktop && currentChecks && currentChecks.length > 0 && { exportList: documents })}
                {...(isDesktop && { exportList: documents })}
                disabledExportList={!(currentChecks && currentChecks.length > 0)}
                isFetchingExport={downloading}
                exportListBsStyle={isDesktop ? `download ${!isValidForm() && "disabled"} ` : "only-icon"}
                backLinkTo="/checks"
                textBack="consult.checks.returnToChecks"
            />

            <MainContainer
                showLoader={isFetching || fetchingForm}
                className="px-0 border-radius-lg box-shadow-small texture-header background-white">
                <Box background="white" className="mt-5 px-7 pt-2 pb-7">
                    <Collapse in={isDesktop ? true : open}>{renderFilterDesktop()}</Collapse>
                    <Box
                        component="article"
                        background="white"
                        borderRadius="default"
                        className={classNames("pt-5 mb-5")}
                        toBorderInMobile>
                        {totalCount > 0 && (
                            <>
                                {!isDesktop ? (
                                    <>
                                        <div className="detail-head-info pt-0">
                                            {renderBalances(selectedAccount?.currency, totalChecks, totalAmountChecks)}
                                        </div>
                                        <Box display="flex" alignX="between" alignY="center" className="pr-5 pl-5 mb-3">
                                            {getResultCounter()}
                                            <Box>
                                                <Button
                                                    label={
                                                        open
                                                            ? "global.productFilters.btnFilter.opened.label"
                                                            : "transactions.button.showFilters"
                                                    }
                                                    image="images/icons/filter.svg"
                                                    className="mt-auto btn-link-without-underline"
                                                    onClick={() => {
                                                        setOpen(!open);
                                                    }}
                                                />
                                                {getTooltipInfo()}
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <div className="detail-head-info pt-0">
                                            {renderBalances(selectedAccount?.currency, totalChecks, totalAmountChecks)}
                                        </div>
                                        <Box display="flex" alignX="between" className="mt-8">
                                            {getResultCounter()}
                                            {getTooltipInfo()}
                                        </Box>
                                    </>
                                )}
                                {!isDesktop ? (
                                    <Box className="pagination-component pagination-checks-content">
                                        <Box className="children-alternate-bg-color">
                                            {currentChecks.map((check) => (
                                                <RemittanceCheckItem
                                                    statusOptionsSelect={statusOptionsSelect}
                                                    check={check}
                                                    onRow={(record) => {
                                                        onRowClick({ ...record, key: record?.checkNumber });
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                ) : (
                                    <Table
                                        className="table-default mt-8"
                                        columns={renderHeaderList()}
                                        data={getChecksData()}
                                        rowKey={(record) => record.key}
                                        emptyText={i18n.get("remittance.checks.list.noRecords")}
                                        onRow={(record) => ({
                                            onClick: () => onRowClick(record),
                                        })}
                                    />
                                )}

                                <Box className="pagination-component mt-5">
                                    <PaginatorButtonsBar
                                        page={totalCount === 0 ? 0 : pageNumber}
                                        totalpages={Math.ceil(totalCount / checksPerPage)}
                                        goToFirstPage={goToFirstPage}
                                        goToPreviousPage={goToPreviousPage}
                                        goToNextPage={() => goToNextPage()}
                                        goToLastPage={goToLastPage}
                                    />
                                </Box>
                            </>
                        )}
                        {!isDesktop && (
                            <Box display="flex" alignX="between" alignY="center" className="pr-5 pl-5 mb-3">
                                {totalCount <= 0 && (
                                    <>
                                        {getResultCounter()}
                                        <Button
                                            label={
                                                open
                                                    ? "global.productFilters.btnFilter.opened.label"
                                                    : "transactions.button.showFilters"
                                            }
                                            image="images/icons/filter.svg"
                                            className="mt-auto btn-link-without-underline"
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                        />
                                    </>
                                )}
                            </Box>
                        )}
                        {totalCount === 0 &&
                            (!afterSearch ? (
                                renderEmptyList()
                            ) : (
                                <Box className="mt-10">
                                    <NoResults description="remittance.checks.list.empty" />
                                </Box>
                            ))}
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

RemittanceChecks.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checks: shape({}).isRequired,
    totalCount: number.isRequired,
    totalChecks: number.isRequired,
    // pageNumber: number.isRequired,
    fetchingForm: bool.isRequired,
    totalAmountChecks: number.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: checksSelectors.getFetching(state),
    checks: checksSelectors.getRemittanceChecks(state),
    totalCount: checksSelectors.getTotalCount(state),
    totalChecks: checksSelectors.getTotalChecks(state),
    totalAmountChecks: checksSelectors.getTotalAmountChecks(state),
    filtersRemittanceChecks: checksSelectors.getFiltersRemittanceChecks(state),
    fetchingForm: formSelectors.getFetching(state),
    formProducts: formSelectors.getCustomProducts(state),
});

export default connect(mapStateToProps)(resizableRoute(RemittanceChecks));
