import React, { useEffect, useMemo, useState } from "react";
import { bool, func, shape, string, arrayOf } from "prop-types";
import * as i18n from "util/i18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import classNames from "classnames";
import Row from "pages/_components/Row";
import { Col, Alert } from "react-bootstrap";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Select from "pages/forms/_components/_fields/Select";
import TextField from "pages/_components/fields/TextField";
import { Field } from "formik";
import Checkbox from "pages/_components/Checkbox";
import Button from "pages/_components/Button";
import SimpleDocumentField from "pages/_components/fields/SimpleDocumentField";
import Image from "pages/_components/Image";
import { validarDocumentoEcuador } from "util/validationEcu";
import FieldError from "pages/_components/fields/FieldError";
import * as config from "util/config";
import { USD_CURRENCY } from "constants.js";
import { fieldInputError, cleanToAlphanumeric } from "./utilForm";

const RequestDataForm = ({
    values,
    errors,
    ID_FORM,
    genericProps,
    accountOptions,
    documentTypes,
    fetchingOffices,
    fetchingValidationCi,
    checkTypeOptions,
    citiesOptions,
    validateCIFullName,
    validatedCI,
    listOffices,
    setFieldValue,
    isDesktop,
    emissionCost,
    onValidateCi,
}) => {
    const [validateCIdisable, setValidateCIdisable] = useState(true);
    const [fullNameDisabled, setFullNameDisabled] = useState(true);

    useEffect(() => () => setFieldValue(`enableWithdraw`, true), []);

    useEffect(() => {
        setFieldValue(`withdrawPersonIdentification`, values?.document?.document);
        setFieldValue(`withdrawPersonIdentificationType`, values?.document?.type);
        setFieldValue(`withdrawFullName`, cleanToAlphanumeric(validateCIFullName));
    }, [validateCIFullName]);

    useEffect(() => {
        setFieldValue(`checkSequential`, emissionCost?.checkSequential);
        setFieldValue(`checkCost`, emissionCost?.checkCost);
        setFieldValue("amount", { currency: USD_CURRENCY, quantity: emissionCost ? +emissionCost.checkCost : 0 });
    }, [emissionCost?.checkSequential]);

    useEffect(() => {
        setFieldValue(`withdrawPersonIdentification`, values?.document?.document);
        setFieldValue(`withdrawPersonIdentificationType`, values?.document?.type);
        if (values?.document?.type === "CED" && values?.document?.document.length === 10) {
            if (!validarDocumentoEcuador(values?.document?.document)) {
                errors.document = i18n.get(`check.request.documentNumber.invalid`);
                setValidateCIdisable(true);
            } else {
                errors.document = "";
                setValidateCIdisable(false);
            }
        } else if (values?.document?.type == "CED" && values?.document?.document.length < 10) {
            setFieldValue(`withdrawFullName`, "");
            setValidateCIdisable(true);
        } else {
            errors.document = "";
            setFieldValue(`withdrawFullName`, "");
            setValidateCIdisable(false);
        }
    }, [values?.document?.type, values?.document?.document]);

    useEffect(() => {
        setFieldValue(`deliveryOfficeCode`, "");
        setFieldValue(`deliveryOfficeDesc`, "");
    }, [values?.cityCode]);

    useEffect(() => {
        if (values?.document?.type == "CED") {
            if (validatedCI) {
                if (validateCIFullName.length > 0) {
                    setFullNameDisabled(true);
                } else {
                    setFullNameDisabled(false);
                }
            } else {
                setFullNameDisabled(true);
            }
        } else {
            setFullNameDisabled(false);
        }
    }, [values?.document?.type, validateCIFullName, validatedCI]);

    const listOfficesMapped = useMemo(
        () =>
            listOffices.map(({ officeCode, officeName }) => ({
                value: officeCode?.toString(),
                label: officeName,
            })),
        [listOffices],
    );

    const onChangeProduct = (value) => {
        const elem = accountOptions?.options?.find((item) => item?.idProduct && item.idProduct === value);
        setFieldValue(`account`, value);
        setFieldValue(`accountNumber`, elem.number);
        setFieldValue(
            `accountDesc`,
            `${elem.numberMask} - ${elem.ownerName} - ${elem.productTypeBackend} - ${elem.currency}`,
        );
        setFieldValue(`accountType`, elem.productTypeBackend);
    };

    const onChangeCheckType = (value) => {
        setFieldValue(`checkTypeCode`, value.value);
        setFieldValue(`checkTypeDesc`, value.label);
    };

    const onChangeCity = (value) => {
        setFieldValue(`cityCode`, value.label);
        setFieldValue(`deliveryOfficeCode`, "");
        setFieldValue(`deliveryOfficeDesc`, "");
    };

    const onChangeDeliveryOffice = (value) => {
        const elem = listOffices?.find((item) => item?.officeCode?.toString() === value.value);
        setFieldValue(`deliveryOfficeCode`, value.value);
        setFieldValue(`deliveryOfficeDesc`, value.label);
        setFieldValue(`deliveryOfficeHours`, elem.officeHours);
        setFieldValue(`deliveryOfficeAddress`, elem.officeAddress);
    };

    const onChangeFullName = (value) => {
        setFieldValue(`withdrawFullName`, value);
    };

    const onChangeIdentification = (event) => {
        const { name, value } = event.target;
        const { type } = values?.document;

        if (type === "CED" && !/^\d*$/.test(value)) {
            const validValue = value.slice(0, -1);
            setFieldValue(name, {
                type: values?.document?.type,
                document: validValue,
            });
            return false;
        } else {
            const passportRegex = new RegExp(config.get("regex.pattern.valid.passport"));
            return passportRegex.test(value);
        }
    };

    const handlerEnableWithdraw = () => {
        setFieldValue(`enableWithdraw`, !values.enableWithdraw);
        if (!values.enableWithdraw) {
            setFieldValue("document", {
                type: "CED",
                document: "",
            });
            setFieldValue(`withdrawPersonIdentification`, "");
            setFieldValue(`withdrawPersonIdentificationType`, "");
        }
    };

    return (
        <>
            <Box
                borderRadius="lg"
                background="white"
                className={classNames("mt-7", {
                    "box-shadow-small p-7": isDesktop,
                })}>
                <Box className="mt-5">
                    <Text bold labelKey={`${ID_FORM}.subtitle`} />
                </Box>
                <Row gapY="0">
                    <Col md={6}>
                        <Box className="data-label-special-mb mt-6">
                            <Text
                                semibold
                                labelKey="check.request.send.accountNumber.label"
                                size={isDesktop ? "5" : "3"}
                            />
                        </Box>
                        <Box
                            fullWidth
                            className={classNames({
                                "has-error": errors.accountNumber,
                            })}>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={accountOptions}
                                key="account"
                                name="account"
                                idField="account"
                                value={values?.account}
                                noLabelEditMode
                                customPlaceholder={i18n.get(`check.request.send.accountNumber.placeholder`)}
                                optionClassName="needsclick"
                                renderAs="combo"
                                onChangeCustom={onChangeProduct}
                            />
                            {fieldInputError(errors.accountNumber)}
                        </Box>
                    </Col>
                    <Col md={6}>
                        <Box className="data-label-special-mb mt-6">
                            <Text semibold labelKey="check.request.send.checkType.label" size={isDesktop ? "5" : "3"} />
                        </Box>
                        <Select
                            id="checkType"
                            name="checkType"
                            label="check.request.send.checkType.label"
                            onChange={(value) => onChangeCheckType(value)}
                            options={checkTypeOptions.map(({ code, description }) => ({
                                value: code,
                                label: description,
                            }))}
                            value={values.checkTypeCode}
                            valueKey="value"
                            placeholder={i18n.get(`check.request.send.checkType.placeholder`)}
                            containerClassName="input-group selector-custom"
                            className={classNames({
                                "has-error": errors.checkTypeCode,
                            })}
                        />
                        {fieldInputError(errors.checkTypeCode)}
                    </Col>
                    {values?.accountNumber != "" && values?.checkTypeCode != "" && (
                        <>
                            <Row
                                display="flex"
                                className={classNames("align-items-end", {
                                    "gap-0": !isDesktop,
                                })}>
                                <Col md={6}>
                                    <Box
                                        fullWidth
                                        className={classNames({
                                            "has-error": errors.checkSequential,
                                        })}>
                                        <Field
                                            component={TextField}
                                            key="checkSequential"
                                            name="checkSequential"
                                            idField="checkSequential"
                                            labelText={i18n.get(`check.request.send.checkSequential.label`)}
                                            value={values.checkSequential}
                                            placeholderText={i18n.get(`check.request.send.checkSequential.label`)}
                                            disabled
                                            clearable={false}
                                        />
                                        {fieldInputError(errors.checkSequential)}
                                    </Box>
                                </Col>
                                <Col md={3}>
                                    <Box className="data-label-special-mb mt-6">
                                        <Text
                                            semibold
                                            labelKey="check.request.send.city.label"
                                            size={isDesktop ? "5" : "3"}
                                        />
                                    </Box>
                                    <Select
                                        id="city"
                                        name="city"
                                        label="check.request.send.city.label"
                                        onChange={(value) => onChangeCity(value)}
                                        options={citiesOptions.map(({ code, description }) => ({
                                            value: code,
                                            label: description,
                                        }))}
                                        value={values.cityCode}
                                        valueKey="value"
                                        placeholder={i18n.get(`check.request.send.city.placeholder`)}
                                        containerClassName="input-group selector-custom"
                                        className={classNames({
                                            "has-error": errors.cityCode,
                                        })}
                                    />
                                    {fieldInputError(errors.cityCode)}
                                </Col>
                                <Col md={3}>
                                    <Box className="data-label-special-mb mt-6">
                                        <Text
                                            semibold
                                            labelKey="check.request.send.deliveryOffice.label"
                                            size={isDesktop ? "5" : "3"}
                                        />
                                    </Box>
                                    <Select
                                        id="deliveryOffice"
                                        name="deliveryOffice"
                                        label="check.request.send.deliveryOffice.label"
                                        onChange={onChangeDeliveryOffice}
                                        options={listOfficesMapped}
                                        avoidChangeOneOption
                                        value={values.deliveryOfficeCode}
                                        disabled={listOffices.length <= 0 || fetchingOffices}
                                        placeholder={i18n.get(`check.request.send.deliveryOffice.placeholder`)}
                                        containerClassName="input-group selector-custom"
                                        className={classNames({
                                            "has-error": errors.deliveryOfficeCode,
                                        })}
                                    />
                                    {fieldInputError(errors.deliveryOfficeCode)}
                                </Col>
                            </Row>
                            {values?.deliveryOfficeCode != "" && (
                                <Col md={12}>
                                    <Box background="background-disabled" className="p-5 mt-5 mb-4" borderRadius="lg">
                                        <Row>
                                            <Col xs={2} md={1} className="d-flex align-items-center">
                                                <Image src="images/checks/map.svg" />
                                            </Col>
                                            {isDesktop && (
                                                <Col md={11}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text semibold>
                                                                {values.cityCode} - {values.deliveryOfficeDesc}
                                                            </Text>
                                                        </Col>
                                                        <Col md={12}>
                                                            {i18n.get(`check.request.alert.address`)}{" "}
                                                            {values.deliveryOfficeAddress}{" "}
                                                            <span className="pl-5">
                                                                {" "}
                                                                {i18n.get(`check.request.alert.hours`)}{" "}
                                                                {values.deliveryOfficeHours}{" "}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            {!isDesktop && (
                                                <>
                                                    <Col xs={10} className="d-flex my-auto">
                                                        <Text semibold className="">
                                                            {values.cityCode} - {values.deliveryOfficeDesc}
                                                        </Text>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                {i18n.get(`check.request.alert.address`)}{" "}
                                                                {values.deliveryOfficeAddress}{" "}
                                                            </Col>
                                                            <Col xs={12}>
                                                                {i18n.get(`check.request.alert.hours`)}{" "}
                                                                {values.deliveryOfficeHours}{" "}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </Box>
                                </Col>
                            )}
                            <Col md={12}>
                                <Box bottom="4" left="5" className="mr-auto mt-5">
                                    <Checkbox
                                        checked={values.enableWithdraw}
                                        name="enableWithdraw"
                                        labelText="check.request.send.enableWithdraw.label"
                                        onChange={() => {
                                            handlerEnableWithdraw();
                                        }}
                                    />
                                </Box>
                            </Col>
                        </>
                    )}
                </Row>
            </Box>
            {values?.enableWithdraw && values?.accountNumber != "" && values?.checkTypeCode != "" && (
                <>
                    <Box
                        borderRadius="lg"
                        className={classNames("mt-7", {
                            "box-shadow-small p-7": isDesktop,
                        })}>
                        <Box className="mt-5">
                            <Text bold labelKey={`${ID_FORM}.send.withdrawTitle.label`} size={isDesktop ? "5" : "3"} />
                        </Box>
                        <Row gapY="0">
                            <Col
                                xs={values?.document?.type == "CED" ? 9 : 12}
                                md={values?.document?.type == "CED" ? 4 : 6}>
                                <Box
                                    fullWidth
                                    className={classNames({
                                        "has-error": errors.document,
                                    })}>
                                    <Field
                                        autoComplete="off"
                                        idForm={ID_FORM}
                                        clearable={false}
                                        component={SimpleDocumentField}
                                        name="document"
                                        data={{ documentTypes }}
                                        defaultDocumentType="CED"
                                        defaultCountry="EC"
                                        handleDocumentChange={onChangeIdentification}
                                        maxLength={values?.document?.type === "CED" ? 10 : 20}
                                        labelWithSemibold={true}
                                    />
                                    <Box className="mt-2">
                                        {errors.document && <FieldError error={errors.document} />}
                                    </Box>
                                </Box>
                            </Col>
                            {values?.document?.type == "CED" && (
                                <>
                                    <Col xs={3} md={2} className="select-button-inline">
                                        <Button
                                            block
                                            bsStyle="primary"
                                            label="check.request.withdrawValidate"
                                            onClick={() => onValidateCi()}
                                            disabled={validateCIdisable}
                                            loading={fetchingValidationCi}
                                        />
                                    </Col>
                                </>
                            )}
                            <Col md={6}>
                                <Box
                                    fullWidth
                                    className={classNames({
                                        "has-error": errors.withdrawFullName,
                                    })}>
                                    <Field
                                        component={TextField}
                                        key="withdrawFullName"
                                        name="withdrawFullName"
                                        idField="withdrawFullName"
                                        labelText={i18n.get(`check.request.withdrawFullName`)}
                                        pattern={config.get("regex.pattern.valid.onlyChars")}
                                        value={values.withdrawFullName}
                                        placeholderText={i18n.get(`check.request.withdrawFullName.placeholder`)}
                                        clearable={false}
                                        disabled={fullNameDisabled}
                                        maxLength={75}
                                        onInputChange={(value) => onChangeFullName(value)}
                                    />
                                    {fieldInputError(errors.withdrawFullName)}
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </>
            )}
        </>
    );
};

RequestDataForm.propTypes = {
    isDesktop: bool.isRequired,
    ID_FORM: string,
    genericProps: shape({}),
    accountOptions: arrayOf(shape({})),
    documentTypes: arrayOf(shape({})),
    checkTypeOptions: arrayOf(shape({})),
    citiesOptions: arrayOf(shape({})),
    validateCIFullName: string,
    validatedCI: bool,
    listOffices: arrayOf(shape({})),
    emissionCost: shape({}),
    fetchingOffices: bool,
    fetchingValidationCi: bool,
    values: shape({}),
    errors: shape({}),
    setFieldValue: func.isRequired,
    onValidateCi: func.isRequired,
};

RequestDataForm.defaultProps = {
    isDesktop: false,
    ID_FORM: "",
    genericProps: {},
    accountOptions: [],
    documentTypes: [],
    checkTypeOptions: [],
    citiesOptions: [],
    validateCIFullName: "",
    validatedCI: false,
    listOffices: [],
    emissionCost: {},
    fetchingOffices: false,
    fetchingValidationCi: false,
    values: {},
    errors: {},
};

export default RequestDataForm;
