import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import I18n from "pages/_components/I18n";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import NoResults from "pages/_components/NoResultsMessage";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";
import Link from "react-router-dom/Link";
import Info from "pages/_components/Info";
import classNames from "classnames";
import moment from "moment";
import Text from "pages/_components/Text";
import { dictionaryStatusCreditCardCorporate } from "util/creditCards.js";
import Checkbox from "pages/_components/fields/Checkbox";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { connect } from "react-redux";
import { formatNumber } from "util/format";

class CreditCardCorporateMovementsConsumption extends Component {
    static propTypes = {
        statements: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
        dispatch: func,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        dispatch: () => {},
        completeFavorites: false,
    };

    state = {
        selectAllChecked: false,
        updateSelected: 0,
        selected: [],
        checkAll: false,
    };

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(creditCardActions.setMovementConsumeScreen("CONSUMOS"));
    }

    handleCheckClick = (value, statement) => {
        const { dispatch, statements } = this.props;
        const movementsConsumeToPayAux = [];

        const updatedStatements = statements.map((item) => {
            if (item.idStatement === statement.idStatement) {
                return { ...item, checkToPay: value?.currentTarget?.checked };
            }
            return item;
        });
        dispatch(creditCardActions.corporateMovementConsumeCreditCardSuccess(updatedStatements));

        const selectAll = statements
            .filter((item) => item.status === "VIGENTE")
            .every((item) => item.checkToPay === true);

        this.setState({
            checkAll: !selectAll,
            selected: movementsConsumeToPayAux,
            updateSelected: movementsConsumeToPayAux.length,
        });
    };

    handleCheckClickAll = () => {
        const { dispatch, statements } = this.props;
        const selectAll = statements
            .filter((statement) => statement.status === "VIGENTE")
            .every((item) => item.checkToPay === true);
        let updatedStatements = [];
        if (selectAll) {
            updatedStatements = statements.map((item) => ({
                ...item,
                checkToPay: item.status === "VIGENTE" ? false : item.checkToPay,
            }));
        } else {
            updatedStatements = statements.map((item) => ({
                ...item,
                checkToPay: item.status === "VIGENTE" ? true : item.checkToPay,
            }));
        }

        dispatch(creditCardActions.corporateMovementConsumeCreditCardSuccess(updatedStatements));
        this.setState({
            checkAll: !selectAll,
        });
    };

    renderList = () => {
        const { statements, movementsConsumeToPay } = this.props;
        if (!statements.length) {
            return null;
        }
        return (
            <>
                {statements.length === 0 && <>{this.renderEmptyResults()}</>}
                {statements.length > 0 && (
                    // children-bg-color
                    <Box className="children-bg-color paginate-table children-alternate-bg-color full-height full-width">
                        {isDesktop && (
                            <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                <Checkbox
                                    onChange={() => this.handleCheckClickAll()}
                                    hideLabel
                                    // id={statements.length}
                                    name="selectAllMovements"
                                    // checked={checkAll}
                                    checked={statements
                                        .filter((statement) => statement.status === "VIGENTE")
                                        .every((item) => item.checkToPay === true)}
                                    disabled={statements.every((statement) => statement.status !== "VIGENTE")}
                                />
                                <Box display="flex" fullWidth alignY="center" className="py-5 size-6 pr-1 mr-6">
                                    <I18n id="creditCard.movement.details.date" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="py-5 size-6 pr-1 mr-1">
                                    <span>Código de consumo</span>
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="py-5 size-6 pr-1 mr-6">
                                    <I18n id="checks.table.item.status" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="py-5 size-6 pr-1 mr-1">
                                    <span>F. Inicio</span>
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="py-5 size-6 pr-1 mr-1">
                                    <span>F. Venc</span>
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 justify-content-flex-end">
                                    <I18n id="tableHeader.amountUsd" />
                                </Box>
                            </Box>
                        )}
                        {statements.map((statement) => (
                            <>
                                {isDesktop ? (
                                    <Col xs={12} md={6}>
                                        <Box
                                            display="flex"
                                            alignX="between"
                                            alignY="center"
                                            className="border-radius-lg mb-2">
                                            <Checkbox
                                                onChange={(value) => this.handleCheckClick(value, statement)}
                                                hideLabel
                                                id={statement.idStatement}
                                                name={statement.idStatement.toString()}
                                                checked={statement.checkToPay}
                                                disabled={statement.status !== "VIGENTE"}
                                            />
                                            <Link
                                                aria-describedby={statement.internalNumber}
                                                className="row-credit-card full-width border-radius-lg"
                                                to={`/CorporateStatementDetail/${statement.idUsedCreditCard}/${statement.idStatement}`}>
                                                <Box fullWidth>
                                                    <Box
                                                        display={isDesktop ? "flex" : "grid"}
                                                        alignY="center"
                                                        gapX="5"
                                                        fullWidth
                                                        className="item-account-list p-5 border-radius-lg justify-content-between">
                                                        <Info
                                                            className={classNames("info-account-list")}
                                                            labelText={moment(statement.date).format("DD/MM/YYYY")}
                                                        />
                                                        <Info
                                                            className={classNames("info-account-list")}
                                                            labelText={statement.idStatement}
                                                        />
                                                        <Info
                                                            className={classNames(
                                                                "info-account-list display-flex justify-content-center py-2 mr-6",
                                                                dictionaryStatusCreditCardCorporate(
                                                                    statement.type,
                                                                    statement.status,
                                                                ),
                                                            )}
                                                            labelText={(
                                                                statement.status.charAt(0).toUpperCase() +
                                                                statement.status.slice(1).toLowerCase()
                                                            ).replaceAll("_", " ")}
                                                        />
                                                        <Info
                                                            className={classNames("info-account-list")}
                                                            labelText={moment(statement.startDate).format("DD/MM/YYYY")}
                                                        />
                                                        <Info
                                                            className={classNames("info-account-list")}
                                                            labelText={moment(statement.expirationDate).format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                        />

                                                        <Info
                                                            className={classNames("info-account-list", {
                                                                "text-right": isDesktop,
                                                            })}
                                                            labelText={formatNumber(statement.amount)}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Link>
                                        </Box>
                                    </Col>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignX="between"
                                        alignY="center"
                                        className="border-radius-lg mb-4 pos-relative">
                                        <Checkbox
                                            onChange={(value) => this.handleCheckClick(value, statement)}
                                            hideLabel
                                            id={statement.idStatement}
                                            name={statement.idStatement.toString()}
                                            checked={statement.checkToPay}
                                            disabled={statement.status !== "VIGENTE"}
                                        />
                                        <Link
                                            aria-describedby={statement.internalNumber}
                                            className="row-credit-card full-width border-radius-lg"
                                            to={`/CorporateStatementDetail/${statement.idUsedCreditCard}/${statement.idStatement}`}>
                                            <Box display="flex">
                                                <Box
                                                    display="flex"
                                                    alignY="center"
                                                    column
                                                    gapX="5"
                                                    fullWidth
                                                    className="item-account-list p-5 justify-content-start mt-8">
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3"
                                                    />
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="center"
                                                        column
                                                        className="mb-5">
                                                        <Info
                                                            className="info-account-list color-text-grey"
                                                            labelText="Fecha"
                                                        />
                                                        <Info
                                                            className={classNames("info-account-list size-4")}
                                                            labelText={moment(statement.date).format("DD/MM/YYYY")}
                                                        />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="center"
                                                        column
                                                        className="mb-5">
                                                        <Info
                                                            className="info-account-list color-text-grey"
                                                            labelText="Código de consumo"
                                                        />
                                                        <Info
                                                            className="info-account-list size-4"
                                                            labelText={statement.idStatement}
                                                        />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3">
                                                        <Info
                                                            className="info-account-list color-text-grey"
                                                            labelText="F. Inicio"
                                                        />

                                                        <Info
                                                            className="info-account-list size-4"
                                                            labelText={moment(statement.startDate).format("DD/MM/YYYY")}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Box display="flex" alignY="start" column className="p-5">
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3">
                                                        <Info
                                                            className={classNames(
                                                                "info-account-list display-flex justify-content-center py-2",
                                                                dictionaryStatusCreditCardCorporate(
                                                                    statement.type,
                                                                    statement.status,
                                                                ),
                                                            )}
                                                            labelText={(
                                                                statement.status.charAt(0).toUpperCase() +
                                                                statement.status.slice(1).toLowerCase()
                                                            ).replaceAll("_", " ")}
                                                        />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3">
                                                        <Info
                                                            className="info-account-list text-right mt-3 color-text-grey"
                                                            labelText="F. Venc"
                                                        />
                                                        <Info
                                                            className="info-account-list text-right size-4"
                                                            labelText={moment(statement.expirationDate).format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                        />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3">
                                                        <Info
                                                            className="info-account-list text-right color-text-grey"
                                                            labelKey="tableHeader.amountUsd"
                                                        />
                                                        <Info
                                                            className="info-account-list text-right size-4"
                                                            labelText={formatNumber(statement.amount)}
                                                        />{" "}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Link>
                                    </Box>
                                )}
                            </>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

const mapStateToProps = (state) => ({
    movementsConsumeToPay: creditCardSelectors.getMovementsConsumeToPay(state),
});

CreditCardCorporateMovementsConsumption.propTypes = {
    movementsConsumeToPay: shape({}),
};

CreditCardCorporateMovementsConsumption.defaultProps = {
    movementsConsumeToPay: [],
};
export default connect(mapStateToProps)(CreditCardCorporateMovementsConsumption);
