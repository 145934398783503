import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { arrayOf, bool, date, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCard";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import { actions as productRequestActions } from "reducers/productRequest";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import * as i18n from "util/i18n";
import NoResults from "pages/_components/NoResultsMessage";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import Box from "pages/_components/Box";
import classNames from "classnames";
import Text from "pages/_components/Text";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import CreditCardListCorporate from "pages/creditCards/CreditCardListCorporate.jsx";
import CreditCardTabs from "./CreditCardTabs.jsx";
import NoProduct from "./../../pages/desktop/widgets/_components/NoProduct.jsx";

class CreditCardsCorporate extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isRequestAvailable: bool,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        corporateCreditCards: arrayOf(
            shape({
                productAlias: string,
                availableBalance: number,
                balance: number,
                number: oneOfType([number, string]),
                shortLabel: string,
                availableBalanceCurrency: string,
                expirationDate: date,
                label: string,
            }),
        ).isRequired,
        loggedUser: shape({
            bankType: string,
        }),
        showProductIcon: bool,
    };

    static defaultProps = {
        isRequestAvailable: false,
        loggedUser: null,
        showProductIcon: bool,
    };

    state = {
        showNoLimitAccountRequestModal: false,
        searchWord: "",
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        items: [
            { id: "principal", isAditional: false },
            // { id: "aditional", isAditional: true },
        ],
        typeIniIndex: 0,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(creditCardsActions.corporateCreditCardRequest());
        dispatch(creditCardsActions.clearCorporateMovementConsumeRequest());

        // dispatch(creditCardsActions.listRequest("/creditCards"));
        // dispatch(creditCardsActions.clearFilters());
    }

    showAccountRequestModal = () => {
        this.setState({
            showNoLimitAccountRequestModal: true,
        });
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    displayProductRequestModal = () => {
        const { dispatch } = this.props;
        dispatch(productRequestActions.modalShow("TC"));
    };

    renderHeader = () => {
        const {
            isRequestAvailable,
            isMobile,
            loans,
            // loggedUser
        } = this.props;

        if (isMobile) {
            return (
                <Head
                    backLinkTo="/creditcards"
                    titleText="Tarjetas Corporativas"
                    // {...optionToCreatLoanMobile}
                />
            );
        }

        return (
            <Head
                textBack="creditCard.detail.returnToList"
                backLinkTo="/creditcards"
                titleText="Tarjetas Corporativas"
            />
        );
    };

    // btnHandlerOnClick = () => {
    //     const { dispatch } = this.props;
    //     dispatch(push("/form/creditCardRequest/"));
    // };

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ searchWord: value });
    };

    getFilteredItems(creditCards, item) {
        let filteredItems = [];
        if (creditCards && creditCards.length > 0) {
            filteredItems = creditCards?.filter((e) => e.isAditional == item.isAditional);
        }
        return filteredItems;
    }

    renderItem(item) {
        const { corporateCreditCards, isDesktop, showProductIcon } = this.props;
        const { rowsPerPage, searchWord } = this.state;
        const filteredCreditCards = corporateCreditCards.filter(
            (creditCard) =>
                creditCard.numberMask?.toLowerCase().includes(searchWord.toLowerCase()) ||
                creditCard.official?.toLowerCase().includes(searchWord.toLowerCase()) ||
                creditCard.cardStyle?.toLowerCase().includes(searchWord.toLowerCase()) ||
                creditCard.nameOnCard?.toLowerCase().includes(searchWord.toLowerCase()),
        );
        // if (filteredCreditCards && filteredCreditCards.length) {
        return (
            <Box
                key={item.internalNumber}
                isDesktop={isDesktop}
                keyLabel={`creditCard.list.${item.internalNumber}.label`}>
                <Box className="mt-7" fullWidth>
                    <TextSimpleField
                        name="search"
                        placeholder={i18n.get("creditCard.corporative.search.placeholder")}
                        value={searchWord}
                        onChange={this.handleChange}
                        serarchStyle
                    />
                </Box>
                {filteredCreditCards && filteredCreditCards.length ? (
                    <PaginatedDataTable
                        nameList="corporateCreditCards"
                        data={filteredCreditCards}
                        hasFilterApplied={filteredCreditCards.length !== corporateCreditCards.length}
                        rowsPerPage={rowsPerPage}
                        component={CreditCardListCorporate}
                        componentProps={{
                            showProductIcon,
                            // completeFavorites,
                        }}
                    />
                ) : (
                    <Box className="mt-10">
                        <NoResults />
                    </Box>
                )}
            </Box>
        );
    }

    selectItem = (idx) => {
        this.setState({
            typeIniIndex: idx,
        });
    };

    getItems() {
        const { corporateCreditCards } = this.props;
        const { items } = this.state;
        // const itemsFinal = items.filter((c) => corporateCreditCards.filter((l) => l.isAditional === c.isAditional).length > 0);
        const itemsFinal = corporateCreditCards;

        return itemsFinal;
    }

    render() {
        const { fetching, isMobile, corporateCreditCards } = this.props;
        const isLoading = fetching && !corporateCreditCards.length;
        const { showNoLimitAccountRequestModal } = this.state;
        const items = this.getItems();
        return (
            <>
                <Notification scopeToShow="creditcardsCorporate" />
                <TextAndButtonsModal
                    modalShow={showNoLimitAccountRequestModal}
                    acceptFunction={() => {
                        this.setState({
                            showNoLimitAccountRequestModal: false,
                        });
                    }}
                    headingText={i18n.get("productRequest.drawer.account.noLimit.title")}
                    text={i18n.get("productRequest.drawer.account.noLimit.text")}
                    loading={false}
                    modalId="productRequest.drawer.account.noLimitBalance"
                    textClassName="mx-md-0"
                />
                {!isLoading && this.renderHeader()}
                <MainContainer
                    showLoader={isLoading}
                    // className="main-container border-radius-lg p-7 box-shadow-small background-white texture-header">
                    className={classNames("main-container box-shadow-small background-white texture-header", {
                        "p-7": !isMobile,
                        "p-5": isMobile,
                    })}>
                    <Col xs={12} md={8} mdOffset={2}>
                        {corporateCreditCards.length ? (
                            <>
                                <Box
                                    component="article"
                                    display="flex"
                                    borderRadius="default"
                                    fullWidth
                                    className={classNames("box-shadow-small", {
                                        "px-7 py-5 mb-5": !isMobile,
                                        "p-5 mb-5": isMobile,
                                    })}>
                                    <Box fullWidth className="mb-1 mr-5">
                                        <Box
                                            className={classNames({
                                                "my-3": isMobile,
                                                "mb-4 mt-6 mt-2": !isMobile,
                                            })}>
                                            <Text
                                                labelKey="accounts.balanceComposed.a11y"
                                                className="visually-hidden"
                                            />
                                            <Text
                                                {...(!isMobile && { size: "4" })}
                                                {...(isMobile && { size: "2" })}
                                                semibold
                                                labelKey="creditCards.corporative.detail.title"
                                            />
                                        </Box>
                                        <Text
                                            className="line-height-15"
                                            color="text-grey-color"
                                            {...(!isMobile && { size: "6" })}
                                            {...(isMobile && { size: "5" })}
                                            labelKey="creditCards.detail.subtitle"
                                            light
                                        />
                                    </Box>
                                    <Box display="flex">
                                        <Image src="images/creditCards.svg" className="img-header-card" />
                                    </Box>
                                </Box>
                                {items && items.length === 1 && (
                                    <>
                                        {/* {items[0].isAditional ? (
                                            <Box className="mb-5">
                                                <Text size="5" bold labelKey="creditCard.title.aditional.label" />{" "}
                                            </Box>
                                        ) : ( */}
                                        <Box className="mb-5">
                                            <Text size="5" bold labelKey="creditCard.title.principal.label" />{" "}
                                        </Box>
                                        {/* )} */}
                                    </>
                                )}

                                {/* <CreditCardTabs onSelect={(index) => this.selectItem(index)}>
                                    {items && items.length === 1
                                        ? this.renderItem(items[0])
                                        : items.map((item) => this.renderItem(item))}
                                </CreditCardTabs> */}
                                <CreditCardTabs onSelect={(index) => this.selectItem(index)}>
                                    {this.renderItem(items[0])}
                                </CreditCardTabs>
                            </>
                        ) : (
                            <NoProduct text="desktop.creditCards.corporate.empty" showContacts={false} subText="desktop.creditCards.corporate.empty.subtext"/>
                        )}
                    </Col>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: creditCardsSelectors.isFetchingCorporate(state),
    corporateCreditCards: creditCardsSelectors.getCorporateCreditCards(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(CreditCardsCorporate);
