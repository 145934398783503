import React, { useEffect, useState } from "react";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import classNames from "classnames";
import Image from "pages/_components/Image";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import Dropdown from "pages/_components/Dropdown";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/fields/Checkbox";
import { filterData } from "util/array";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import NumberFormat from "react-number-format";
import { countDecimalPlaces, numberFormat, toNumber } from "util/number";
import { push } from "react-router-redux";
import Loader from "pages/_components/Loader";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { getCategorySelected } from "./hooks/servicePaymentsHooks";
import ServiceListMobile from "./_components/ServiceListMobile";
import FooterMassivePay from "./_components/FooterMassivePay";
import { renderDataModal } from "./_components/utilForm";
import { PREFIX } from "./hooks/servicePaymentFormHooks";

export const FORM_ID = "servicePayments.registeredServices.list";
export const ID_ACTIVITY = `payService.list`;
export const MAX_TO_SELECT = config.getInteger(`${FORM_ID}.maxToSelect"`, 5);
export const ROWS_PER_PAGE = config.getInteger("table.rowsPerPage", 10);

const RegisteredServiceList = ({
    fetching,
    listPayService,
    listPayServiceDebt,
    listPayServiceSelected,
    search,
    currentPage,
    dispatch,
    isDesktop,
    lang,
    maximumDecimals,
    minimumDecimals,
    fetchingDebt,
    fetchingNewPay,
    preDataServicePay,
    match: { params },
}) => {
    const [filteredServices, setFilteredServices] = useState([]);
    const [selected, setSelected] = useState(listPayServiceSelected || new Map());
    const [categorySelected, setCategorySelected] = useState({});
    const [loadingRows, setLoadingRows] = useState({});
    const [page, setPage] = useState(1);
    const [searchWord, setSearchWord] = useState(search);
    const [fetchingDebtClick, setFetchingDebtClick] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [serviceDetail, setServiceDetail] = useState({});

    const { decimalSeparator, thousandSeparator } = numberFormat(lang);

    const onFinishCheckDebtPay = ({ service, debt }) => {
        if (debt) {
            const servicePay = { ...service, debt, hasDue: true, amountPay: debt.total };
            dispatch(payServiceActions.setServicePayment(servicePay, undefined, 1, ""));
            dispatch(push("/servicePayments/form"));
        }
    };

    useEffect(() => {
        setCategorySelected(getCategorySelected(params?.idServicePayment)[0]);
        if (params?.id && preDataServicePay && Number(params?.id) === preDataServicePay?.id) {
            const { category, identificationValue } = preDataServicePay;
            dispatch(payServiceActions.clean());
            dispatch(payServiceActions.newPayService(category, identificationValue, onFinishCheckDebtPay));
            // dispatch(replace(`/servicePayments/registered/${category}`));
        } else {
            if (!listPayService) {
                dispatch(payServiceActions.listPayServiceRequest(params?.idServicePayment));
            }
        }
    }, []);

    useEffect(() => {
        if (listPayService) {
            setFilteredServices([...listPayService]);
        }
    }, [listPayService]);

    useEffect(() => {
        if (search) {
            handleChangeText(search, false);
        }
    }, []);

    useEffect(() => {
        setFetchingDebtClick(fetchingNewPay);
    }, [fetchingNewPay]);

    const handleBack = () => {
        dispatch(push("/servicePayments"));
    };

    const handleGoClick = (url) => {
        dispatch(push(url));
    };

    const optionsMenu = [
        {
            label: "servicePayments.btn.enroll",
            onClick: () => {
                handleGoClick("/servicePayments/registerService/form");
            },
        },
    ];

    const options = [
        {
            label: `${FORM_ID}.option.pay`,
            onClick: (id) => {
                payService(id);
            },
        },
        {
            label: `${FORM_ID}.option.edit`,
            onClick: (id) => {
                const service = listPayService.find((serv) => serv.id === id);
                dispatch(payServiceActions.setServiceModify(service, page, searchWord));
                dispatch(push("/servicePayments/modify/alias"));
            },
        },
        {
            label: `${FORM_ID}.option.view`,
            onClick: (id) => {
                if (isDesktop) {
                    setServiceDetail(listPayService.find((serv) => serv.id === id));
                    setShowModalDetail(true);
                } else {
                    dispatch(payServiceActions.setDataServiceForDetail(listPayService.find((serv) => serv.id === id)));
                    dispatch(push("/servicePayments/view/detail"));
                }
            },
        },
        {
            label: `${FORM_ID}.option.delete`,
            onClick: (id) => {
                const service = listPayService.find((serv) => serv.id === id);
                dispatch(payServiceActions.setServiceDelete(service, page, searchWord));
                dispatch(push("/servicePayments/delete"));
            },
        },
    ];

    const handleChangeText = (filter, clean = true) => {
        setSearchWord(filter);
        if (clean) {
            // cada que se use el buscador se limpia la seleccion
            setSelected(new Map());
        }
        if (filter !== "") {
            const data = filterData([...listPayService], ["companyLabel", "alias"], filter);
            setFilteredServices(data);
        } else {
            setFilteredServices([...listPayService]);
        }
    };

    const setAmount = (id, amount) => {
        const selectedMap = new Map(selected);
        selectedMap.set(id, { amount });
        setSelected(selectedMap);
    };

    const toPayService = (serviceId, debt) => {
        const service = listPayService.find((serv) => serv.id === serviceId);
        const payService = { ...service, debt, hasDue: true, amountPay: debt?.total };

        dispatch(payServiceActions.setServicePayment(payService, undefined, page, searchWord));
        dispatch(push("/servicePayments/form"));
    };

    const onFinish = ({ serviceId, isMassive, debt }) => {
        if (isMassive) {
            const amount = debt?.total;
            setLoadingRows((prev) => ({ ...prev, [serviceId]: false }));
            if (amount) {
                setAmount(serviceId, amount);
            }
        } else if (debt) {
            toPayService(serviceId, debt);
        } else {
            setFetchingDebtClick(false);
        }
    };

    const handleCheckClick = (idChecked, debt) => {
        const selectedMap = new Map(selected);
        if (!selectedMap.has(idChecked)) {
            if (selected.size === MAX_TO_SELECT) {
                dispatch(
                    notificationActions.showNotification(i18n.get(`${FORM_ID}.massive.pay.max.allowed`), "warning", [
                        "servicePayments",
                    ]),
                );
                return;
            }
            if (debt && debt?.paymentMethodBill === "UNICO_CRONOLOGICO") {
                toPayService(idChecked, debt);
            }
            if (debt && debt?.total) {
                setAmount(idChecked, debt?.total);
            } else {
                // consultar deuda
                dispatch(payServiceActions.checkDebtRequest(idChecked, onFinish));
                setLoadingRows((prev) => ({ ...prev, [idChecked]: true }));
            }
        } else {
            selectedMap.delete(idChecked);
            setSelected(selectedMap);
        }
    };

    const payService = (id) => {
        if (listPayServiceDebt[id]) {
            const { debt, hasDue } = listPayServiceDebt[id];
            if (hasDue) {
                const service = listPayService.find((serv) => serv.id === id);
                const servicePay = { ...service, debt, hasDue, amountPay: debt.total };
                dispatch(payServiceActions.setServicePayment(servicePay, undefined, page, searchWord));
                dispatch(push("/servicePayments/form"));
            }
        } else {
            setFetchingDebtClick(true);
            dispatch(payServiceActions.checkDebtRequest(id, onFinish, false));
        }
    };

    const onRowClick = ({ id }) => {
        payService(id);
    };

    const handleAmountChange = (id, value) => {
        const amount = parseFloat(value.replace(/,/g, ""));
        if (!isNaN(amount)) {
            setAmount(id, amount);
        } else {
            setAmount(id, 0);
        }
    };

    const handleGoToPay = () => {
        // pago masivo
        if (selected.size > 1) {
            const list = [];
            selected.forEach(({ amount }, key) => {
                if (listPayServiceDebt[key]) {
                    const { debt } = listPayServiceDebt[key];
                    const { hasDue } = listPayServiceDebt[key];
                    // checked = listPayServiceDebt[id].checked;
                    // amountPay = listPayServiceDebt[id].amountPay;

                    const service = listPayService.find((serv) => serv.id === key);
                    list.push({ ...service, debt, hasDue, amountPay: amount });
                }
            });
            dispatch(payServiceActions.setListMassivePayment(list, totalAmount(), selected, page, searchWord));
            dispatch(push("/servicePayments/massive/form"));
        } else {
            selected.forEach(({ amount }, key) => {
                if (listPayServiceDebt[key]) {
                    const { debt } = listPayServiceDebt[key];
                    const { hasDue } = listPayServiceDebt[key];
                    const service = listPayService.find((serv) => serv.id === key);
                    const servicePay = { ...service, debt, hasDue, amountPay: amount };
                    dispatch(payServiceActions.setServicePayment(servicePay, undefined, page, searchWord));
                }
            });
            dispatch(push("/servicePayments/form"));
        }
    };

    const totalAmount = () => {
        let total = 0;
        selected.forEach((sel) => {
            total += sel.amount;
        });
        return total;
    };

    const isValidAmount = () => {
        let isValid = true;
        selected.forEach(({ amount }) => {
            if (amount < 0.01) {
                isValid = false;
            }
        });
        return isValid;
    };

    const renderServiceDetail = () => {
        const {
            category,
            alias,
            companyLabel,
            agreementLabel,
            identificationLabel,
            identificationValue,
        } = serviceDetail;

        return (
            <>
                <Box className="mt-7">
                    <Text bold size="2" labelKey="servicePayments.registeredServices.detail.title" />
                </Box>

                <Box className="mt-8 mb-5">
                    <Text bold size="6" labelKey="servicePayments.massive.pay.detail.service.title" />
                </Box>
                <Box>
                    {renderDataModal({
                        labelKey: "servicePayments.pay.category",
                        data: i18n.get(`servicePayment.category.${category}`),
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.service`,
                        data: companyLabel,
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.agreement`,
                        data: agreementLabel,
                    })}
                    {renderDataModal({
                        labelKey: "servicePayments.pay.identificationType",
                        data: identificationLabel,
                    })}
                    {renderDataModal({
                        label: identificationLabel,
                        data: identificationValue,
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.alias`,
                        data: alias,
                    })}
                    {/* {renderDataModal({
                        xs: 4,
                        labelKey: "servicePayments.pay.titular",
                        data: clientName || "-",
                    })} */}
                    {/* {renderDataModal({
                        labelKey: `${PREFIX}.dueDate`,
                        date: dueDate,
                        data: !dueDate && "-",
                    })} */}
                </Box>

                <Box className="text-center mt-8">
                    <Button
                        bsStyle="outline"
                        label="global.return"
                        className="btn-width-lg"
                        onClick={() => {
                            setShowModalDetail(false);
                        }}
                    />
                </Box>
            </>
        );
    };

    const generateTableColumns = () => {
        const result = [
            {
                key: "check",
                dataIndex: "check",
                width: 30,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                align: "left",
                key: "service",
                dataIndex: "service",
                title: i18n.get(`${FORM_ID}.service`),
                width: 180,
            },
            {
                align: "left",
                key: "alias",
                dataIndex: "alias",
                title: i18n.get(`${FORM_ID}.alias`),
                width: 170,
            },
            {
                key: "dueDate",
                dataIndex: "dueDate",
                title: i18n.get(`${FORM_ID}.dueDate`),
                width: 125,
            },
            {
                align: "right",
                key: "due",
                dataIndex: "due",
                title: i18n.get(`${FORM_ID}.due`),
                width: 150,
            },
            {
                align: "right",
                key: "amount",
                dataIndex: "amount",
                title: i18n.get(`${FORM_ID}.amount`),
                width: 150,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                align: "right",
                key: "actions",
                dataIndex: "actions",
                width: 30,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
        ];

        return result;
    };

    /* eslint-disable no-nested-ternary */
    const populateData = (data) =>
        data &&
        data.map((service) => {
            const { id, companyLabel, alias } = service;
            let debt = null;
            let hasDue = true;
            // let checked = false;
            // let amountPay = undefined;
            if (listPayServiceDebt[id]) {
                debt = listPayServiceDebt[id].debt;
                hasDue = listPayServiceDebt[id].hasDue;
                // checked = listPayServiceDebt[id].checked;
                // amountPay = listPayServiceDebt[id].amountPay;
            }
            let result = {
                id,
                check: (
                    <Checkbox
                        onChange={() => handleCheckClick(id, debt)}
                        hideLabel
                        id={id}
                        name={id.toString()}
                        // checked={checked}
                        checked={selected.has(id)}
                        disabled={!hasDue}
                    />
                ),
                key: id,
                service: <Text bold defaultValue={companyLabel} />,
                alias,
                dueDate: debt ? (
                    debt?.dueDate ? (
                        <FormattedDate date={debt?.dueDate} anotherFormat="DD/MM/YYYY" />
                    ) : (
                        "-"
                    )
                ) : (
                    ""
                ),
                due: debt?.total ? (
                    <FormattedAmount quantity={debt?.total} className="justify-content-end" noCurrency />
                ) : (
                    ""
                ),
                amount: "",
                actions: (
                    <Dropdown
                        image="images/icons/dropdown-dots.svg"
                        className={classNames({ "dropdown-service-op": isDesktop })}
                        pullRight>
                        {options.map((item) => (
                            <Button
                                key={item.label}
                                label={item.label}
                                onClick={() => {
                                    item.onClick(id);
                                }}
                                className="dropdown__item-btn"
                                bsStyle="link"
                            />
                        ))}
                    </Dropdown>
                ),
            };
            if (hasDue && selected.has(id)) {
                const { amount } = selected.get(id);
                const decimalPlaces = amount ? countDecimalPlaces(amount, decimalSeparator) : 0;
                const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
                const value = toNumber(amount.toString(), decimalSeparator);
                result = {
                    ...result,
                    amount: (
                        <Box>
                            <NumberFormat
                                allowNegative={false}
                                className={classNames("form-control  text-left input-item-pay-service", {
                                    "input-border": debt?.paymentMethod !== "TOTAL",
                                    "input-error": value <= 0,
                                })}
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                maxLength="15"
                                onChange={(e) => handleAmountChange(id, e.target.value)}
                                thousandSeparator={thousandSeparator}
                                type="text"
                                value={value}
                                autoComplete="off"
                                inputMode="decimal"
                                disabled={debt?.paymentMethod === "TOTAL"}
                                fixedDecimalScale
                            />
                            {value <= 0 && (
                                <div>
                                    <Text
                                        color="text-grey"
                                        size="6"
                                        className="mr-5"
                                        labelKey={`${FORM_ID}.item.amount.validation`}
                                    />
                                </div>
                            )}
                        </Box>
                    ),
                };
            }

            return result;
        });

    const rowClassName = (record) => (loadingRows[record.key] ? "loading-row" : "");

    const noRecords = () => (
        <Box>
            <Box className="m-7">
                <Image src="images/empty.svg" />
            </Box>
            <Box className="text-center">
                <Text bold size="3" labelKey={`${FORM_ID}.empty.title`} />
            </Box>
            <Box className="text-center mt-5">
                <Text labelKey={`${FORM_ID}.empty.info`} />
            </Box>
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head
                title={`${FORM_ID}.title`}
                textBack="servicePayment.returnToServices"
                onBack={handleBack}
                rightChildren={
                    isDesktop ? (
                        <Box display="flex" gap="7">
                            <Button
                                bsStyle="primary"
                                className="btn-width-lg"
                                label="servicePayments.btn.enroll"
                                onClick={() => {
                                    handleGoClick("/servicePayments/registerService/form");
                                }}
                            />
                        </Box>
                    ) : (
                        <Dropdown image="images/icons/dropdown-dots.svg">
                            {optionsMenu.map((item) => (
                                <Button
                                    key={item.label}
                                    label={item.label}
                                    onClick={() => {
                                        item.onClick();
                                    }}
                                    className="dropdown__item-btn"
                                    bsStyle="link"
                                />
                            ))}
                        </Dropdown>
                    )
                }
            />
            <MainContainer
                showLoader={fetching}
                className={classNames(
                    "main-container border-radius-lg box-shadow-small background-white texture-header service-payments",
                    {
                        "mb-login-layout": isDesktop,
                    },
                    {
                        "p-0": !isDesktop,
                    },
                )}>
                <Box
                    background="white"
                    borderRadius="lg"
                    className={classNames({
                        "p-7 mt-4": isDesktop,
                        "p-5 mt-4": !isDesktop,
                        "pointer-events-none": fetchingDebt,
                    })}>
                    <Box
                        // borderRadius="lg"
                        fullWidth
                        display="flex"
                        className="px-7 pb-4 mb-7">
                        <Box display="flex" alignY="center" className="ml-3 mr-6">
                            <Image
                                src={`images/icons/servicesCategories/${categorySelected.logo}`}
                                className="img-icon-service"
                            />
                        </Box>
                        <Box display="flex" alignY="center" fullWidth>
                            <Box fullWidth>
                                <Box>
                                    <Text
                                        component="h3"
                                        size={!isDesktop ? "3" : null}
                                        labelKey={`servicePayment.category.${categorySelected?.idServicePayment}`}
                                        bold
                                    />
                                </Box>
                                {categorySelected?.idServicePayment === "AUTOMOTORES_PEATONES" ||
                                categorySelected?.idServicePayment === "IMPUESTOS_OBLIGACIONES" ? (
                                    <Box className="mt-4">
                                        <Text labelKey={`${FORM_ID}.service.info`} color="text-grey-color" light />
                                    </Box>
                                ) : (
                                    <>
                                        {isDesktop && (
                                            <Box className="mt-4">
                                                <Text
                                                    labelKey={`${FORM_ID}.massive.info`}
                                                    color="text-grey-color"
                                                    light
                                                    records={MAX_TO_SELECT}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <TextSimpleField
                        name="search"
                        placeholder={i18n.get(`${FORM_ID}.search.placeholder`)}
                        value={searchWord}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeText(e.target.value)}
                        serarchStyle
                        maxLength="60"
                    />

                    {isDesktop ? (
                        <PaginatedDataTable
                            className="overflowNone no-col-group"
                            data={filteredServices}
                            columns={generateTableColumns()}
                            populateRows={populateData}
                            startPage={currentPage}
                            rowsPerPage={ROWS_PER_PAGE}
                            onRowClick={onRowClick}
                            hasFilterApplied={listPayService && filteredServices.length !== listPayService.length}
                            // messageNoRecords="administration.signatures.list.noRecords"
                            noRecords={noRecords}
                            rowClassNameFunc={rowClassName}
                            onChangePage={setPage}
                        />
                    ) : (
                        <ServiceListMobile
                            filteredServices={filteredServices}
                            rowsPerPage={ROWS_PER_PAGE}
                            hasFilterApplied={listPayService && filteredServices.length !== listPayService.length}
                            options={options}
                            payService={payService}
                            noRecords={noRecords}
                        />
                    )}
                </Box>
                {fetchingDebtClick && <Loader fixed />}

                {isDesktop && selected?.size > 0 && (
                    <FooterMassivePay
                        cant={selected.size}
                        totalPay={totalAmount()}
                        labelButton={`${FORM_ID}.btn.pay`}
                        onClick={handleGoToPay}
                        disabledButton={!isValidAmount()}
                    />
                )}
                <SideBarModal
                    show={showModalDetail}
                    dialogClassName="modal-detail-pay-service"
                    onClose={() => {
                        setShowModalDetail(false);
                    }}>
                    <Box className="px-9">{showModalDetail && <>{renderServiceDetail()}</>}</Box>
                </SideBarModal>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetchingServices(state),
    fetchingDebt: payServiceSelectors.isFetchingDebt(state),
    fetchingNewPay: payServiceSelectors.isFetchingNewPay(state),
    currentPage: payServiceSelectors.getPage(state),
    search: payServiceSelectors.getSearch(state),
    listPayService: payServiceSelectors.getListPayService(state),
    listPayServiceDebt: payServiceSelectors.getlistPayServiceDebt(state),
    listPayServiceSelected: payServiceSelectors.getlistPayServiceSelected(state),
    preDataServicePay: payServiceSelectors.getPreDataServicePay(state),
    lang: i18nSelectors.getLang(state),
    maximumDecimals: config.getInteger("defaultDecimal.maximum"),
    minimumDecimals: config.getInteger("defaultDecimal.minimum"),
});

RegisteredServiceList.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    fetchingDebt: bool,
    listPayService: arrayOf(shape({})),
    isDesktop: bool,
    lang: string.isRequired,
    match: shape({
        params: shape({ idServicePayment: string.isRequired }),
    }).isRequired,
    maximumDecimals: number.isRequired,
    minimumDecimals: number.isRequired,
};

RegisteredServiceList.defaultProps = {
    fetching: false,
    fetchingDebt: false,
    isDesktop: true,
    listPayService: undefined,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RegisteredServiceList));
