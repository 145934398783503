import React from "react";
import { node, string } from "prop-types";
import { isDesktop } from "react-device-detect";
import Box from "./Box";
import Text from "./Text";
import Image from "./Image";

const Help = ({ titleKey, textKey, defaultValueTitle, defaultValueText, iconSrc, children }) => (
    <>
        {isDesktop ? (
            <Box className="p-4" background="background-disabled" borderRadius="lg">
                <Box display="flex">
                    <Box>
                        <Image className="help-img" src={iconSrc || "images/icons/icon_notice.svg"} />
                    </Box>
                    <Box className="help-text-container ml-4">
                        {titleKey && (
                            <Box>
                                <Text
                                    className="help-text-1"
                                    labelKey={titleKey}
                                    bold
                                    defaultValue={defaultValueTitle}
                                />
                            </Box>
                        )}
                        {textKey && (
                            <Box className="mt-3">
                                <Text
                                    color="text-grey"
                                    className="help-text-2"
                                    size="6"
                                    labelKey={textKey}
                                    defaultValue={defaultValueText}
                                />
                            </Box>
                        )}
                    </Box>
                    {children && <Box>{children}</Box>}
                </Box>
            </Box>
        ) : (
            <Box className="p-4" background="background-disabled" borderRadius="lg">
                <Box display="flex">
                    <Box>
                        <Image className="help-img" src={iconSrc || "images/icons/icon_notice.svg"} />
                    </Box>
                    <Box className="help-text-container ml-4">
                        {titleKey && (
                            <Box>
                                <Text
                                    className="help-text-1"
                                    labelKey={titleKey}
                                    bold
                                    defaultValue={defaultValueTitle}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" className="flex-direction-column">
                    {textKey && (
                        <Box className="my-5">
                            <Text
                                color="text-grey"
                                className="help-text-1"
                                size="6"
                                labelKey={textKey}
                                defaultValue={defaultValueText}
                            />
                        </Box>
                    )}
                </Box>
                {children && <Box>{children}</Box>}
            </Box>
        )}
    </>
);

Help.propTypes = {
    titleKey: string,
    textKey: string,
    defaultValueTitle: string,
    defaultValueText: string,
    iconSrc: string,
    children: node.isRequired,
};

Help.defaultProps = {
    titleKey: undefined,
    textKey: undefined,
    defaultValueTitle: undefined,
    defaultValueText: undefined,
    iconSrc: undefined,
};

export default Help;
