import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import { bool, func, string } from "prop-types";
import React, { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import classNames from "classnames";
import Tooltip from "pages/_components/Tooltip";

const Filters = ({ isDesktop, typeOperation, resultCounter, renderFilters }) => {
    const [open, setOpen] = useState(true);

    return (
        <>
            {!isDesktop && (
                <Row>
                    <Col xs={typeOperation === "N" ? 5 : 4}>
                        <Box
                            display="flex"
                            alignY="center"
                            alignX="left"
                            className="full-width justify-content-between"
                            fullHeight>
                            {resultCounter()}
                        </Box>
                    </Col>
                    <Col xs={typeOperation === "N" ? 7 : 8}>
                        <Box display="flex" alignY="center" alignX="end" full>
                            <Button
                                label={
                                    open
                                        ? "global.productFilters.btnFilter.opened.label"
                                        : "transactions.button.showFilters"
                                }
                                bsStyle="text"
                                image="images/icons/filter.svg"
                                className={classNames("mt-auto", {
                                    "p-0": typeOperation === "N",
                                })}
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            />
                            <Tooltip
                                buttonId="tooltipFilter"
                                image="images/circle-information-green.svg"
                                text="documents.swift.tooltip.transaction"
                                position="top-right"
                                className="swift-widget-tooltip"
                                onHover={isDesktop}
                            />
                        </Box>
                    </Col>
                </Row>
            )}

            <Collapse in={isDesktop ? true : open}>{renderFilters()}</Collapse>

            {isDesktop && (
                <Row className="mt-7">
                    <Col xs={6}>
                        <Box
                            display="flex"
                            alignY="center"
                            alignX="left"
                            className="full-width justify-content-between"
                            full>
                            {resultCounter()}
                        </Box>
                    </Col>
                </Row>
            )}
        </>
    );
};

Filters.propTypes = {
    isDesktop: bool.isRequired,
    resultCounter: func.isRequired,
    renderFilters: func.isRequired,
};

Filters.defaultProps = {};

export default Filters;
