import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import { dictionaryMiniatureCard, dictionaryStatusCreditCardCorporate } from "util/creditCards.js";
import NoResults from "pages/_components/NoResultsMessage";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";
import Link from "react-router-dom/Link";
import Info from "pages/_components/Info";
import classNames from "classnames";
import moment from "moment";
import Text from "pages/_components/Text";
import Movement from "./Movement";
import FormattedAmount from "pages/_components/FormattedAmount";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { connect } from "react-redux";
import { formatNumber } from "util/format";

class CreditCardCorporateMovements extends Component {
    static propTypes = {
        statements: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    renderList = () => {
        const { statements, isRequestAvailable } = this.props;

        if (!statements.length) {
            return null;
        }
        return (
            <>
                {statements.length === 0 && <>{this.renderEmptyResults()}</>}
                {statements.length > 0 && (
                    // children-bg-color
                    <Box className="children-bg-color paginate-table children-alternate-bg-color full-height full-width">
                        {isDesktop && (
                            <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-1">
                                    <I18n id="creditCard.movement.details.date" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-1">
                                    <I18n id="tableHeader.movementType" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-1">
                                    <I18n id="tableHeader.expiracyDate" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-10">
                                    <I18n id="checks.table.item.status" />
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 justify-content-flex-end">
                                    <I18n id="tableHeader.amountUsd" />
                                </Box>
                            </Box>
                        )}{" "}
                        {/* {statements.map((statement, index) => (
                            <>
                                {isDesktop ? (
                                    <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                        statementDesktop
                                    </Box>
                                ) : (
                                    <Box borderRadius="md">statementMobile</Box>
                                )}
                            </>
                        ))} */}
                        {statements.map((statement) => (
                            <>
                                {isDesktop ? (
                                    <Col xs={12} md={6}>
                                        <Box
                                            display="flex"
                                            alignX="between"
                                            alignY="center"
                                            className="border-radius-lg mb-4">
                                            <Link
                                                aria-describedby={statement.internalNumber}
                                                className="row-credit-card full-width border-radius-lg"
                                                to={`/CorporateStatementDetail/${statement.idUsedCreditCard}/${statement.idStatement}`}>
                                                <Box fullWidth>
                                                    <Box
                                                        display={isDesktop ? "flex" : "grid"}
                                                        alignY="center"
                                                        gapX="5"
                                                        fullWidth
                                                        className="item-account-list p-5 border-radius-lg justify-content-between">
                                                        <Info
                                                            className="info-account-list"
                                                            labelText={moment(statement.date).format("DD/MM/YYYY")}
                                                        />
                                                        <Info
                                                            className="info-account-list text-bold"
                                                            labelText={
                                                                statement.type.charAt(0).toUpperCase() +
                                                                statement.type
                                                                    .slice(1)
                                                                    .toLowerCase()
                                                                    .replaceAll("_", " de ")
                                                            }
                                                        />
                                                        <Info
                                                            className="info-account-list"
                                                            labelText={moment(statement.expirationDate).format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                        />
                                                        <Info
                                                            className={classNames(
                                                                "info-account-list display-flex justify-content-center py-2",
                                                                dictionaryStatusCreditCardCorporate(
                                                                    statement.type,
                                                                    statement.status,
                                                                ),
                                                                {
                                                                    "text-right": isDesktop,
                                                                },
                                                            )}
                                                            labelText={(
                                                                statement.status.charAt(0).toUpperCase() +
                                                                statement.status.slice(1).toLowerCase()
                                                            ).replaceAll("_", " ")}
                                                        />
                                                        <Info
                                                            className={classNames("info-account-list", {
                                                                "text-right": isDesktop,
                                                            })}
                                                            labelText={
                                                                statement.type === "CONSUMOS"
                                                                    ? formatNumber(statement.amount)
                                                                    : formatNumber(statement.chargeAmount)
                                                            }
                                                        />
                                                        {/* <Text
                                                    className={classNames("info-account-list", {
                                                        "text-right": isDesktop,
                                                    })}
                                                    labelKey={
                                                        statement.type === "CONSUMOS"
                                                            ? statement.amount
                                                            : statement.chargeAmount
                                                    }
                                                /> */}
                                                    </Box>
                                                </Box>
                                            </Link>
                                        </Box>
                                    </Col>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignX="between"
                                        alignY="center"
                                        className="border-radius-lg mb-4">
                                        <Link
                                            aria-describedby={statement.internalNumber}
                                            className="row-credit-card full-width border-radius-lg"
                                            to={`/CorporateStatementDetail/${statement.idUsedCreditCard}/${statement.idStatement}`}>
                                            <Box display="flex">
                                                <Box
                                                    display="flex"
                                                    alignY="center"
                                                    column
                                                    gapX="5"
                                                    fullWidth
                                                    className="item-account-list p-5 justify-content-start">
                                                    <Info
                                                        className="info-account-list"
                                                        labelText={moment(statement.date).format("DD/MM/YYYY")}
                                                    />
                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="center"
                                                        column
                                                        className="mb-5">
                                                        <Info
                                                            className={"info-account-list"}
                                                            labelKey="tableHeader.movementType"
                                                            text={
                                                                statement.type.charAt(0).toUpperCase() +
                                                                statement.type
                                                                    .slice(1)
                                                                    .toLowerCase()
                                                                    .replaceAll("_", " ")
                                                            }
                                                        />
                                                    </Box>

                                                    <Box
                                                        display="flex"
                                                        fullWidth
                                                        alignY="start"
                                                        column
                                                        className="mb-3">
                                                        <Info
                                                            className="info-account-list"
                                                            labelKey="tableHeader.expiracyDate"
                                                        />

                                                        <Info
                                                            className="info-account-list"
                                                            labelText={moment(statement.expirationDate).format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box display="flex" alignY="start" column className="p-5">
                                                    <Info
                                                        className={classNames(
                                                            "info-account-list display-flex justify-content-center py-2 text-center",
                                                            dictionaryStatusCreditCardCorporate(
                                                                statement.type,
                                                                statement.status,
                                                            ),
                                                        )}
                                                        labelText={(
                                                            statement.status.charAt(0).toUpperCase() +
                                                            statement.status.slice(1).toLowerCase()
                                                        ).replaceAll("_", " ")}
                                                    />
                                                    <FormattedAmount
                                                        noAmountMargin
                                                        className={
                                                            "data-amount-account size-3 color-text mt-3 justify-content-end"
                                                        }
                                                        currency="USD"
                                                        quantity={
                                                            statement.type === "CONSUMOS"
                                                                ? statement.amount?.toLocaleString("en-US")
                                                                : statement.chargeAmount?.toLocaleString("en-US")
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </Link>
                                    </Box>
                                )}
                            </>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(CreditCardCorporateMovements);
