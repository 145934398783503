import * as API from "middleware/api";
import { getDeviceId, isMobileNative } from "util/device";

export const recoveryPassStep1 = (username, email) =>
    API.executeAnonymous("/session.recoverPassword.step1", {
        _usernameToReset: username,
        _emailUser: email,
        _deviceID: isMobileNative ? getDeviceId() : "web",
    });

export const recoveryPassStep2 = (_secondFactor, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step2",
        {
            _secondFactor,
        },
        exchangeToken,
    );

export const recoveryPassStep3 = (newPassword, newPasswordRepeat, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step3",
        {
            _newPassword: newPassword,
            _newPasswordRepeat: newPasswordRepeat,
        },
        exchangeToken,
    );

export const recoveryPassStep4 = (newPassword, newPasswordRepeat, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step4",
        {
            _newPassword: newPassword,
            _newPasswordRepeat: newPasswordRepeat,
        },
        exchangeToken,
    );

export const recoveryPassResendOtp = (exchangeToken) =>
    API.executeWithExchangeToken("/session.recoveryPassword.resendOtp", {}, exchangeToken);
