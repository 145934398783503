import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bool, func, shape, arrayOf, oneOfType, string } from "prop-types";
import { isDesktop } from "react-device-detect";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";
import { push } from "react-router-redux";

import { actions as formActions } from "reducers/form";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCardOtherBank";
import {  selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import * as config from "util/config";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import CreditCardList from "pages/creditCards/CreditCardList";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import NoProduct from "../../../desktop/widgets/_components/NoProduct";
import { getCurrentEnvironmentId } from "util/creditCards";

const CreditCardsOtherBanks = (props) => {
    const { isMobile, dispatch, fetching, creditCards, environments, currentEnvironment } = props;
    const isLoading = fetching && !creditCards.length;

    const [searchWord, setSearchWord] = useState("");
    const [rowsPerPage] = useState(config.getInteger("forms.creditCards.otherBanks.maxRecords", 10));

    useEffect(() => {
        dispatch(formActions.preCustomForm(["creditcard.payment.otherbanks.pay"]));
    }, []);

    useEffect(() => {
        dispatch(settingsActions.getEnvironmentsRequest());
    }, []);

    useEffect(() => {
        dispatch(creditCardsActions.getCreditCards(1, searchWord));
    }, []);

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchWord(value);
    };

    const onBack = () => {
        dispatch(push("/creditcards"));
    };

    const options = [
        {
            label: "creditCard.otherBanks.button.newCard",
            onClick: () => dispatch(push("/creditcards/other-banks/addCard")),
        },
    ];

    const renderHeader = () => (
        <Head
            onBack={onBack}
            titleText={i18n.get("creditCard.otherBanks.title")}
            textTitleClassName="m-0"
            textBack="creditCard.otherBanks.label.returnToBankReferences"
            rightChildren={
                isDesktop ? (
                    <Box display="flex" gap="7">
                        <Button
                            image="images/icons/circlePlus.svg"
                            bsStyle="primary"
                            className="btn-width-lg"
                            label="creditCard.otherBanks.button.newCard"
                            onClick={() => dispatch(push("/creditcards/other-banks/addCard"))}
                        />
                    </Box>
                ) : (
                    <Dropdown image="images/icons/dropdown-dots.svg">
                        {options.map((item) => (
                            <Button
                                key={item.label}
                                label={item.label}
                                onClick={() => {
                                    item.onClick();
                                }}
                                className="dropdown__item-btn"
                                bsStyle="link"
                            />
                        ))}
                    </Dropdown>
                )
            }
        />
    );

    const renderList = () => {
        const { showProductIcon } = props;

        if (!creditCards || creditCards.length === 0) {
            return (
                <>
                    <Box className="mt-7 mb-3" fullWidth>
                        <TextSimpleField
                            name="search"
                            placeholder={i18n.get("creditCard.otherBanks.search.placeholder")}
                            value={searchWord}
                            onChange={handleChange}
                            serarchStyle
                        />
                    </Box>
                    <NoProduct text="creditCard.otherBanks.empty.title" subText= "creditCard.otherBanks.empty.desc"  showContacts={false} />
                </>
            );
        }

        const filteredCreditCards = creditCards.filter(
            (creditCard) =>
                creditCard.alias?.toLowerCase().includes(searchWord.toLowerCase()) ||
                creditCard.number?.toLowerCase().includes(searchWord.toLowerCase()),
        );

        return (
            <Box isDesktop={isDesktop}>
                <Box className="mt-7" fullWidth>
                    <TextSimpleField
                        name="search"
                        placeholder={i18n.get("creditCard.otherBanks.search.placeholder")}
                        value={searchWord}
                        onChange={handleChange}
                        serarchStyle
                    />
                </Box>
                <PaginatedDataTable
                    isDesktop
                    showNumbersResults
                    nameList="creditCards"
                    data={filteredCreditCards}
                    hasFilterApplied={filteredCreditCards.length !== creditCards.length}
                    rowsPerPage={rowsPerPage}
                    component={CreditCardList}
                    componentProps={{
                        showProductIcon,
                        isOtherBanks: true,
                    }}
                />
            </Box>
        );
    };

    return (
        <>
            <Notification scopeToShow="creditcardsOtherBanks" />
            {!isLoading && renderHeader()}
            <MainContainer
                showLoader={isLoading}
                className={classNames("main-container box-shadow-small background-white texture-header", {
                    "p-7": !isMobile,
                    "p-5": isMobile,
                })}>
                <Col xs={12} md={8} mdOffset={2}>
                    <>
                        <Box
                            component="article"
                            display="flex"
                            borderRadius="default"
                            fullWidth
                            className={classNames("box-shadow-small", {
                                "px-7 py-5 mb-5": !isMobile,
                                "p-5 mb-5": isMobile,
                            })}>
                            <Box fullWidth className="mb-1 mr-5">
                                <Box
                                    className={classNames({
                                        "my-3": isMobile,
                                        "mb-4 mt-6 mt-2": !isMobile,
                                    })}>
                                    <Text labelKey="accounts.balanceComposed.a11y" className="visually-hidden" />
                                    <Text
                                        {...(!isMobile && { size: "4" })}
                                        {...(isMobile && { size: "2" })}
                                        semibold
                                        labelKey="creditCard.otherBanks.info.title"
                                        className="line-height-15"
                                    />
                                </Box>
                                <Text
                                    className="line-height-15"
                                    color="text-grey-color"
                                    {...(!isMobile && { size: "6" })}
                                    {...(isMobile && { size: "5" })}
                                    labelKey="creditCard.otherBanks.info.subtitle"
                                    light
                                />
                                <br />
                                <Text
                                    className="line-height-15"
                                    color="text-grey-color"
                                    {...(!isMobile && { size: "6" })}
                                    {...(isMobile && { size: "5" })}
                                    labelKey="global.label.ruc"
                                    semibold
                                />
                                <Text
                                    className="line-height-15 mx-3"
                                    color="text-grey-color"
                                    {...(!isMobile && { size: "6" })}
                                    {...(isMobile && { size: "5" })}
                                    light>
                                    {getCurrentEnvironmentId(currentEnvironment, environments)}
                                </Text>
                            </Box>
                            <Box display="flex">
                                <Image src="images/creditCards.svg" className="img-header-card" />
                            </Box>
                        </Box>
                    {renderList()}
                    </>
                </Col>
            </MainContainer>
        </>
    );
};

CreditCardsOtherBanks.defaultProps = {
    creditCards: null,
    showProductIcon: bool,
    isRequestAvailable: false,
};

CreditCardsOtherBanks.propTypes = {
    environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    isRequestAvailable: bool,
    showProductIcon: bool,
    creditCards: arrayOf(shape({})),
};

const mapStateToProps = (state) => ({
    environments: settingsSelectors.getEnvironments(state),
    fetching: creditCardsSelectors.getFetching(state),
    creditCards: creditCardsSelectors.getCreditCardsList(state),
    currentEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(CreditCardsOtherBanks);
