import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import { isAndroidPlatform } from "util/device";
import * as i18n from "util/i18n";
import { encryptText } from "util/security";
import * as Yup from "yup";
import LoginWrapper from "./LoginWrapper";
import SecuritySealImage from "./SecuritySealImage";

const FORM_ID = "login.step2";

const Step2Content = (props) => {
    const { isDesktop, isMobile, isSubmitting, className, values, handleBack, securitySeal, securitySealAlt } = props;
    const passwordRef = useRef(null);

    useEffect(() => {
        if (props.isUserActiveCorporate !== props.isActiveCorporate) {
            const { setFieldValue } = props;
            setFieldValue("password ", "");
        }
    }, [props.isUserActiveCorporate, props.isActiveCorporate]);

    const scrollToRef = (ref) => () => {
        if (ref && ref.current) {
            if (isAndroidPlatform()) {
                setTimeout(() => {
                    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 150);
            } else {
                ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    };

    useEffect(() => {
        const scrollToPassword = scrollToRef(passwordRef);
        window.addEventListener("keyboardDidShow", scrollToPassword);

        return () => {
            window.removeEventListener("keyboardDidShow", scrollToPassword);
        };
    }, []);

    return (
        <LoginWrapper>
            <Form
                className={classNames("height-fit-content", className, {
                    "pointer-events-none": isSubmitting,
                })}>
                <Box className="step-2-form" display="flex" column fullWidth>
                    <Box className="external-security-seal" display="flex" fullWidth>
                        <Button
                            onClick={handleBack}
                            image="images/arrow_back.svg"
                            ariaLabel="global.back"
                            className="p-2 mr-8 mt-2 height-fit-content min-height-fit"
                        />
                        <Box fullWidth>
                            <Text
                                component="h3"
                                color="heading"
                                size="3"
                                className="mt-0"
                                bold
                                labelKey=""
                                defaultValue={i18n.get("login.step2.title.1")}
                            />
                            <Box display="block">
                                <Text className="size-6 align-center" defaultValue={i18n.get("login.step2.title.2")} />
                                <Text
                                    className="size-6 align-center"
                                    bold
                                    defaultValue={i18n.get("login.step2.title.phone")}
                                />
                            </Box>
                        </Box>
                        <SecuritySealImage
                            securitySeal={securitySeal}
                            securitySealAlt={securitySealAlt}
                            withoutBorder
                        />
                    </Box>
                    <Field
                        hideLabel={isDesktop}
                        idForm={FORM_ID}
                        copyEnabled={false}
                        type="password"
                        name="password"
                        component={Credential}
                        placeholder={i18n.get("login.step2.password.placeholder")}
                        className="mt-7 mb-3"
                        idField="passwordfield"
                        autoFocus={isDesktop}
                        labelText={i18n.get("login.step2.password.label")}
                        autoComplete="off"
                    />
                    <Button
                        className="mt-6 mb-4"
                        type="submit"
                        bsStyle="primary"
                        // label="global.continue"
                        defaultLabelText={i18n.get("global.continue")}
                        btnUppercase={false}
                        loading={isSubmitting}
                        disabled={values?.password?.length < 1}
                        block
                    />
                    {isMobile ? (
                        <Button
                            href="/recoveryPassword/step1"
                            primaryLink
                            bsStyle="box-icon"
                            image="images/iconTitles/boton_recuperar_contrasenia.svg"
                            defaultLabelText={i18n.get("login.step2.resetPassword")}
                            className="px-0 mt-4 full-width flex-row"
                        />
                    ) : (
                        <Button
                            href="/recoveryPassword/step1"
                            primaryLink
                            bsStyle="link"
                            defaultLabelText={i18n.get("login.step2.resetPassword")}
                            className="px-0 mt-4"
                        />
                    )}
                </Box>
            </Form>
        </LoginWrapper>
    );
};

const mapStateToProps = (state) => ({
    securitySeal: loginSelectors.getSecuritySeal(state),
    securitySealAlt: loginSelectors.getSecuritySealAlt(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

Step2Content.propTypes = {
    values: shape({}),
    // isMobileNative: bool.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    className: string,
    isSubmitting: bool.isRequired,
    setFieldValue: func,
    handleBack: func.isRequired,
    isActiveCorporate: bool.isRequired,
    isUserActiveCorporate: bool.isRequired,
    securitySeal: string,
    securitySealAlt: string,
};

Step2Content.defaultProps = {
    values: null,
    setFieldValue: () => {},
    className: null,
    securitySeal: null,
    securitySealAlt: null,
};

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            password: "",
            isActiveCorporate: props.isActiveCorporate,
        }),
        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
            }),
        handleSubmit: ({ password, isActiveCorporate }, formikBag) => {
            formikBag.props.dispatch(
                loginActions.loginStep2Password(encryptText(password), formikBag, isActiveCorporate),
            );
        },
    }),
)(Step2Content);
