import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { selectors as walletSelectors, actions as walletActions } from "reducers/wallet/wallet.reducer";
import { isFirstTimeOpen } from "util/initializationDevice.util";
import { bool, func, string } from "prop-types";
import { isIOSPlatform, isMobileNativeFunc } from "util/device";
import BannerAppleWalletModal from "./BannerAppleWalletModal";

export const BANNER_CREDIT_CARD_OPTION = "CREDIT_CARD";
export const BANNER_DEBIT_CARD_OPTION = "DEBIT_CARD";
export const BANNER_EMPTY_PRODUCT_OPTION = "EMPTY_PRODUCTS";

const BANNER_REDIRECT_MAP = new Map([
    [BANNER_CREDIT_CARD_OPTION, "/creditcards/list"],
    [BANNER_DEBIT_CARD_OPTION, "/debitCards"],
]);

const BannerAppleWalletContainer = ({ dispatch, bannerProduct, isHiddenBanner }) => {
    const [show, setShow] = useState(false);
    const [redirect, setRedirect] = useState("");

    const initValidateBannerShow = async () => {
        if (!isMobileNativeFunc() || !isIOSPlatform()) {
            return;
        }

        const isFistTimeOpenValue = await isFirstTimeOpen();
        if (isFistTimeOpenValue === undefined || (isFistTimeOpenValue !== true && isFistTimeOpenValue !== "true")) {
            return;
        }

        if (isHiddenBanner !== undefined && (isHiddenBanner === true || isHiddenBanner === "true")) {
            return;
        }

        dispatch(walletActions.getParameterBannerWallet());
    };

    useEffect(() => {
        initValidateBannerShow();
        return () => {
            setShow(false);
            setRedirect(undefined);
            dispatch(walletActions.setParameterBannerWallet(undefined));
        };
    }, []);

    const validateBannerShow = (bannerProductRef) => {
        if (!bannerProductRef || bannerProductRef === "") {
            return;
        }
        setShow(true);
        setRedirect(BANNER_REDIRECT_MAP.get(bannerProductRef));
        dispatch(walletActions.setHiddenBanner(true));
    };

    useEffect(() => {
        validateBannerShow(bannerProduct);
    }, [bannerProduct]);

    const redirectCallback = () => {
        if (!redirect || redirect === "") {
            setShow(false);
            return;
        }

        dispatch(routerActions.push(redirect));
    };

    return (
        <BannerAppleWalletModal show={show} redirectCallback={redirectCallback} redirect={redirect} setShow={setShow} />
    );
};

const mapStateToProps = (state) => ({
    bannerProduct: walletSelectors.getBannerProduct(state),
    isHiddenBanner: walletSelectors.isHiddenBanner(state),
});

BannerAppleWalletContainer.propTypes = {
    dispatch: func,
    bannerProduct: string,
    isHiddenBanner: bool,
};

BannerAppleWalletContainer.defaultProps = {
    dispatch: () => {},
    bannerProduct: undefined,
    isHiddenBanner: false,
};

export default connect(mapStateToProps)(BannerAppleWalletContainer);
