import CreditCardsListItem from "pages/creditCards/ListItem";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { shape, func, string } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
// import * as config from "util/config";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as creditCardActions } from "reducers/creditCard";
import * as config from "util/config";
// import WidgetHeader from "./_components/WidgetHeader";
import WithPermissions from "pages/_components/WithPermissions";
import WidgetCardList from "./WidgetCardList";

class CreditCards extends Component {
    static propTypes = {
        // closeButton: node,
        draggableItemProps: shape({}).isRequired,
        dispatch: func.isRequired,
        loggedUser: shape({
            bankType: string,
        }),
    };

    static defaultProps = {
        // closeButton: null,
        loggedUser: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(creditCardActions.listRequest());
    }

    render() {
        const uiAutomationProp = { "data-ui-automation": "widgetCreditCards" };
        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));
        const { draggableItemProps } = this.props;
        return (
            <WidgetCardList
                slider
                item={CreditCardsListItem}
                keyExtractor={(item) => item.idProduct}
                name="creditCards">
                {(list, listLenght) => (
                    <section
                        className={`widget ${listLenght === 2 ? "two-cards" : ""}`}
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        {list}
                        <div className="overlay" />
                        <WithPermissions>
                            <Box display="flex" alignX="between" fullWidth flex1 className="px-md-3" zIndex="0">
                                {listLenght > itemsToShowButton ? (
                                    <Button
                                        className="btn-widget"
                                        href="/creditcards/list"
                                        label="desktop.widgets.portfolio.seeMore"
                                        bsStyle="link"
                                    />
                                ) : null}
                            </Box>
                        </WithPermissions>
                    </section>
                )}
            </WidgetCardList>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(CreditCards);
