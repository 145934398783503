import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as settingsActions } from "reducers/settings";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import Image from "pages/_components/Image";
import AlertInformation from "pages/_components/AlertInformation";
import { push } from "react-router-redux/actions";
import ExitModal from "pages/_components/modal/ExitModal";
import { validatePassword } from "util/credentials";
import { encryptText } from "util/security";

const FORM_ID = "settings.changePassword";

export const getPasswordHelp = () => (
    <ul>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule1" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule2" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule3" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule4" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule5" />
        </li>
    </ul>
);
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showExitModal: false,
        };
    }

    setShowExitModal = (value) => {
        this.setState({ showExitModal: value });
    };

    render() {
        const { isSubmitting, isDesktop, values, dispatch } = this.props;
        const { showExitModal } = this.state;

        const {
            isErrorForm,
            hasSpecialChar,
            hasNumericChar,
            hasUppercase,
            hasLowercase,
            minLength,
            maxLength,
        } = validatePassword(values);

        const handleCancel = () => {
            dispatch(push("/settings/"));
        };
        const handlePreCancel = () => {
            this.setShowExitModal(true);
        };
        const alertMessage = (
            <Box fullWidth className="mb-7 mb-md-7 ">
                <LabelWithIcon
                    type={
                        values.newPassword.length < minLength || values.newPassword.length > maxLength
                            ? "error"
                            : "alert"
                    }
                    text="recovery.password.step3.password.alert.1"
                    textParams={{ minLength, maxLength }}
                />
                <LabelWithIcon
                    type={!hasSpecialChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.2"
                />
                <LabelWithIcon
                    type={!hasNumericChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.3"
                />
                <LabelWithIcon
                    type={!hasUppercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.4"
                />
                <LabelWithIcon
                    type={!hasLowercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.5"
                />
            </Box>
        );

        const isFormValid =
            values.password &&
            values.newPassword &&
            values.newPasswordConfirmation &&
            values.newPassword === values.newPasswordConfirmation &&
            isErrorForm;

        const formButtons = (
            <Box {...(!isDesktop && { className: "mt-auto mb-4" })}>
                <Row>
                    <Box className="main-container p-7">
                        <Row>
                            <Col xs={3} xsOffset={3}>
                                <Button bsStyle="outline" label="global.cancel" onClick={handlePreCancel} block />
                            </Col>
                            <Col xs={3}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    block
                                    disabled={!isFormValid}
                                />
                            </Col>
                        </Row>
                    </Box>
                </Row>
            </Box>
        );

        const formButtonsMobile = (
            <Box className="mt-8 mb-4" display="flex" column fullWidth>
                <Button
                    className="mb-4"
                    bsStyle="primary"
                    label="global.continue"
                    loading={isSubmitting}
                    type="submit"
                    block
                    disabled={!isFormValid}
                />
                <Button bsStyle="outline" label="global.cancel" onClick={handlePreCancel} block />
            </Box>
        );

        return (
            <>
                <Notification scopeToShow="changePassword" />
                <Head
                    title="settings.changePassword"
                    backLinkTo="/settings/"
                    textBack="settings.changePassword.returnToList"
                />
                <MainContainer className={isDesktop ? "pt-0 profile-changepass" : "rounded-border-top"} fullHeight>
                    <Form>
                        <Box display="flex" column fullWidth fullHeight>
                            <Box
                                background="white"
                                className={classNames("mt-md-0 pt-5 pt-md-8 px-5 pb-md-7", {
                                    "box-shadow-small": isDesktop,
                                    "mx-n-5 full-height": !isDesktop,
                                })}>
                                <Row fullHeight>
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Box fullWidth display="flex" className="py-7 mb-3">
                                            <Box fitWidth className="mr-3 pt-3">
                                                <Image
                                                    src="images/icons/icn-password.svg"
                                                    width="24px"
                                                    color="transparent"
                                                />
                                            </Box>
                                            <Box fullWidth column>
                                                <Box>
                                                    <Text
                                                        component="h3"
                                                        labelKey="settings.changePassword.update"
                                                        bold
                                                    />
                                                </Box>
                                                <Box>
                                                    <Text
                                                        {...(!isDesktop && { align: "center" })}
                                                        {...(!isDesktop ? { size: "3" } : { size: "6" })}
                                                        labelKey="settings.changePassword.textInfo"
                                                        className="my-0 mx-auto"
                                                        component="h2"
                                                        align="left"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <AlertInformation
                                            withIcon
                                            background="info"
                                            title="settings.changePassword.alert"
                                            textClassName="align-justify"
                                        />
                                        <Box display="flex" column fullWidth className="my-auto pb-md-9">
                                            <Box display="flex" column className="mb-2 mt-4">
                                                <Field
                                                    name="password"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder={i18n.get(
                                                        "settings.changePassword.password.placeholder",
                                                    )}
                                                    labelClassName="text-bold"
                                                />
                                            </Box>
                                            <Box display="flex" column className="mb-2 mt-4">
                                                <Field
                                                    name="newPassword"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder={i18n.get(
                                                        "settings.changePassword.password.placeholder2",
                                                    )}
                                                    tooltip={getPasswordHelp()}
                                                    labelClassName="text-bold"
                                                />
                                                <Box display="flex" column className="mb-2 mt-4">
                                                    {alertMessage}
                                                </Box>
                                                <Field
                                                    name="newPasswordConfirmation"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder={i18n.get(
                                                        "settings.changePassword.password.placeholder3",
                                                    )}
                                                    labelClassName="text-bold"
                                                />
                                            </Box>
                                            {!isDesktop && formButtonsMobile}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            {isDesktop && formButtons}
                        </Box>
                    </Form>
                </MainContainer>
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={() => handleCancel()}
                    cancelFunction={() => this.setShowExitModal(false)}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    }
}

ChangePassword.propTypes = {
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    dispatch: func,
};

export default compose(
    connect(null),
    resizableRoute,
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: () => ({
            password: "",
            newPassword: "",
            newPasswordConfirmation: "",
        }),

        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordConfirmation: Yup.string()
                    .required(i18n.get(`${FORM_ID}.newPasswordConfirmation.required`))
                    .oneOf([Yup.ref("newPassword"), null], i18n.get("recoverPassword.form.samePassword.validate")),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changePassword(
                    encryptText(values.password),
                    encryptText(values.newPassword),
                    encryptText(values.newPasswordConfirmation),
                    formikBag,
                ),
            );
        },
    }),
)(ChangePassword);
